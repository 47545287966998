const Plugins = '/api/v1/source-plugin'

export const Goal = `${Plugins}/goal`
export const GoalCategories = `${Plugins}/goal-categories`

export const Goals = (memberId: string) => `${Plugins}/goals/${memberId}`
export const GoalLogs = (goalId: string) => `${Plugins}/goal-logs/${goalId}`
export const OpenCareGoalLogs = (
  patientId: string,
  startDate: string,
  endDate: string,
  externalTaskId?: string
) =>
  `${Plugins}/open-care/goals/logs/${patientId}?startDate=${startDate}&endDate=${endDate}${
    externalTaskId ? '&externalTaskId=' + externalTaskId : ''
  }`
export const OpenCareGoals = (id: string) =>
  `${Plugins}/open-care/goals?id=${id}`
export const Member = (memberId: string) => `${Plugins}/user/${memberId}`
export const UpdateGoalStatus = (id: string) => `${Plugins}/goal/status/${id}`
export const Onboarding = (memberId: string) =>
  `${Plugins}/onboarding-data/${memberId}`
export const Appointments = (memberId: string) =>
  `${Plugins}/appointments/${memberId}`
export const AppointmentNotes = (memberId: string) =>
  `${Plugins}/appointment-notes/${memberId}`
export const SaveAppointmentNotes = (id: string) =>
  `${Plugins}/appointment-notes/${id}`
