import {
  ApiKeyAuthentication,
  Source,
} from '@allara-health/source-health-client'
import { createContext, useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'

interface SourceContextInterface {
  source: Source
}

export const SourceContext = createContext<SourceContextInterface>(
  {} as SourceContextInterface
)

interface SourceProviderProps {
  children: React.ReactNode
}

export const SourceProvider: React.FC<SourceProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [source, setSource] = useState<Source | undefined>(undefined)

  useEffect(() => {
    updateSource()
  }, [])

  useEffect(() => {
    setIsLoading(false)
  }, [source])

  const updateSource = async () => {
    setIsLoading(true)

    if (
      process.env.REACT_APP_SOURCE_API_KEY_ID &&
      process.env.REACT_APP_SOURCE_API_KEY_SECRET
    ) {
      setSource(
        new Source(
          process.env.REACT_APP_SOURCE_BASE_URL || '',
          new ApiKeyAuthentication(
            process.env.REACT_APP_SOURCE_API_KEY_ID,
            process.env.REACT_APP_SOURCE_API_KEY_SECRET
          )
        )
      )
    }
  }

  if (!source) {
    return <></>
  }

  return (
    <SourceContext.Provider value={{ source }}>
      {!isLoading && !source && (
        <Container className="d-flex flex-column justify-content-center align-items-center height-full">
          <p className="h1 text-center text-danger pb-6">
            Something went wrong
          </p>
          <p className="text-center fs-6">Please try refreshing the page</p>
        </Container>
      )}
      {!isLoading && source && <>{children}</>}
    </SourceContext.Provider>
  )
}

export const useSourceContext = (): SourceContextInterface =>
  useContext(SourceContext)
