import { memo, useMemo } from 'react'
import {
  PostAppointmentScreenStepper,
  usePostAppointmentContext,
} from '../../../context/PostAppointmentContext/PostAppointmentProvider'
import StepperStep from './StepperStep'

const Stepper: React.FC = () => {
  const { currentScreen, stepper } = usePostAppointmentContext()

  const currentStep = useMemo(
    () =>
      stepper.find((s) => s.screen == currentScreen) ??
      ({
        index: 0,
        title: 'Initial',
        screen: 'initial',
      } as PostAppointmentScreenStepper),
    [stepper, currentScreen]
  )

  return (
    <div className="d-flex flex-column align-item-center justify-content-center mt-4 navigation">
      <div className="d-flex align-items-center">
        {stepper.map((step) => (
          <StepperStep key={step.index} step={step} currentStep={currentStep} />
        ))}
      </div>
    </div>
  )
}

export default memo(Stepper)
