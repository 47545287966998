import { memo } from 'react'
import {
  PostAppointmentScreenStepper,
  usePostAppointmentContext,
} from '../../../context/PostAppointmentContext/PostAppointmentProvider'
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg'

interface StepperStepProps {
  step: PostAppointmentScreenStepper
  currentStep: PostAppointmentScreenStepper
}

const StepperStep: React.FC<StepperStepProps> = ({ step, currentStep }) => {
  const { currentScreen, stepper } = usePostAppointmentContext()

  const isLastStep = step.index === stepper[stepper.length - 1].index
  const isActive = step.screen === currentScreen
  const isComplete = currentStep.index > step.index

  return (
    <>
      <div className="stepper-step">
        <CustomCheckIcon checked={isComplete} isActive={isActive} />
        <div className="title">
          <h1 className="ff-inter-medium fs-3">{step.title}</h1>
        </div>
      </div>
      {!isLastStep && (
        <div
          style={{ position: 'relative', bottom: '11px' }}
          className={`horizontal-line ${
            isComplete ? 'horizontal-line-active' : ''
          }`}
        />
      )}
    </>
  )
}

type CustomCheckIconProp = {
  checked?: boolean
  isActive: boolean
}

const CustomCheckIcon: React.FC<CustomCheckIconProp> = ({
  checked,
  isActive,
}) => {
  return (
    <div
      className={`check d-flex justify-content-center align-items-center text-white ${
        checked ? 'check-filled' : ''
      } ${isActive ? 'check-current' : ''}`}
    >
      <CheckIcon />
    </div>
  )
}

export default memo(StepperStep)
