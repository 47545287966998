import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import App from './containers/Core/App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    service: process.env.REACT_APP_URL,
    sampleRate: 100, // the percentage of sessions to track
  })
}

if (
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: 'prod',
    sampleRate: 100,
    trackInteractions: true,
    replaySampleRate: 100,
    defaultPrivacyLevel: 'mask',
    trackFrustrations: true,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
