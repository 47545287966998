import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useCallback } from 'react'

interface ConfimDialogProps {
  open: boolean
  text: string
  titleText: string
  helperText?: string
  onConfirm?: () => void
  handleClose: () => void
}

const ConfirmDialog: React.FC<ConfimDialogProps> = ({
  open,
  text,
  titleText,
  helperText,
  onConfirm,
  handleClose,
}) => {
  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm()
    }

    handleClose()
  }, [])

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          {helperText && (
            <>
              <br />
              <br />
              {helperText}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {onConfirm && <Button onClick={handleConfirm}>Confirm</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
