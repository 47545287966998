import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useAppointments from '../../hooks/useAppointments'
import PostAppointmentNoteTableRow from './PostAppointmentNoteTableRow'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'
import { Appointment, AppointmentNote } from '../../types/types'

const Initial = () => {
  const { pluginContext } = useSourcePluginContext()
  const {
    updateSelectedNote,
    nextScreen,
    setCurrentScreen,
    updateSelectedAppointment,
  } = usePostAppointmentContext()
  const { completedAppointments: appointments } = useAppointments(
    pluginContext?.member ?? undefined
  )

  const createNew = (appointment: Appointment) => {
    updateSelectedAppointment(appointment)
    nextScreen(appointment)
  }

  const viewNote = (note: AppointmentNote, appointment: Appointment) => {
    updateSelectedNote(note)
    updateSelectedAppointment(appointment)

    setCurrentScreen('review')
  }

  return (
    <div className="initial">
      <div className="appointment-notes-table-container">
        {appointments.map((appointment) => (
          <PostAppointmentNoteTableRow
            key={appointment.id}
            appointment={appointment}
            createNew={createNew}
            viewNote={viewNote}
          />
        ))}
      </div>
    </div>
  )
}

export default Initial
