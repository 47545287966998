import { default as LinkifyWrapper } from 'react-linkify'

interface LinkifyProps {
  children: React.ReactNode
}

const LinkifyComponentDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
) => {
  return (
    <a href={decoratedHref} target="_blank" rel="noreferrer" key={key}>
      {decoratedText}
    </a>
  )
}

const Linkify: React.FC<LinkifyProps> = ({ children }: LinkifyProps) => {
  return (
    <LinkifyWrapper componentDecorator={LinkifyComponentDecorator}>
      <>{children}</>
    </LinkifyWrapper>
  )
}

export default Linkify
