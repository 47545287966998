import { useEffect } from 'react'
import {
  Goal as GoalRoute,
  Goals as GoalsRoute,
  GoalCategories as GoalCategoriesRoute,
  UpdateGoalStatus as UpdateGoalStatusRoute,
} from '../api/routes'
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useAlertContext } from '../context/AlertContext'
import { useSourcePluginContext } from '../context/SourcePluginContext'
import {
  CreateGoalDto,
  Goal,
  GoalCategory,
  UpdateGoalStatusDto,
} from '../types/types'
import { QUERY_KEYS } from '../api/querykeys'
import axios, { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import produce from 'immer'

interface UseGoalsInterface {
  isLoading: boolean
  error: unknown
  goals: Goal[]
  activeGoals: Goal[]
  pausedGoals: Goal[]
  completedGoals: Goal[]
  goalCategories: GoalCategory[]

  handleSaveGoal: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    Goal,
    unknown
  >
  handleCreateGoal: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    CreateGoalDto,
    unknown
  >
  handleUpdateGoalStatus: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    UpdateGoalStatusDto,
    unknown
  >
}

const useGoals = (member?: string): UseGoalsInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { getAuthHeader } = useSourcePluginContext()

  const fetchGoals = async (): Promise<Goal[]> => {
    if (!member) {
      return []
    }

    const authHeader = await getAuthHeader()
    return fetch(`${process.env.REACT_APP_SERVER_URL}${GoalsRoute(member)}`, {
      headers: authHeader,
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return []
        } else {
          setAlertText(`Error loading goals (${res.statusText})`)
          return []
        }
      })
      .catch(() => {
        setAlertText(`Error loading goals`)
        return []
      })
  }

  const {
    isLoading: isLoadingGoals,
    error: errorGoals,
    data,
  } = useQuery([QUERY_KEYS.GOALS, 'member', member ?? 'default'], fetchGoals)

  const goals = useMemo(() => data ?? [], [data])

  const fetchGoalCategories = async (): Promise<GoalCategory[]> => {
    const authHeader = await getAuthHeader()
    return fetch(`${process.env.REACT_APP_SERVER_URL}${GoalCategoriesRoute}`, {
      headers: authHeader,
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return []
        } else {
          setAlertText(`Error loading goal categories (${res.statusText})`)
          return []
        }
      })
      .catch(() => {
        setAlertText(`Error loading goal categories`)
        return []
      })
  }

  const {
    isLoading: isLoadingCategories,
    error: errorCategories,
    data: categoriesData,
  } = useQuery([QUERY_KEYS.GOALS, 'categories'], fetchGoalCategories, {
    staleTime: Infinity,
  })

  const goalCategories = useMemo(() => categoriesData ?? [], [categoriesData])

  const updateGoal = async (goal: Goal) => {
    const authHeader = await getAuthHeader()
    return axios
      .put(`${process.env.REACT_APP_SERVER_URL}${GoalsRoute(goal.id)}`, goal, {
        headers: authHeader,
      })
      .catch((err) => {
        setAlertText(`Unable to save goal.\n\n${err.message}`)
        return null
      })
  }

  const mutateUpdateGoal = useMutation(updateGoal, {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEYS.GOALS),
    onMutate: async (goal: Goal) => {
      await queryClient.cancelQueries(QUERY_KEYS.GOALS)

      const previousData = queryClient.getQueryData<Goal[]>(QUERY_KEYS.GOALS)

      if (previousData) {
        queryClient.setQueryData<Goal[]>(
          QUERY_KEYS.GOALS,
          previousData.map((g) => {
            if (g.id === goal.id) {
              return { ...g, ...goal }
            }

            return g
          })
        )
      }

      return { previousData }
    },
  })
  const handleSaveGoal = mutateUpdateGoal.mutate

  const createGoal = async (goal: CreateGoalDto) => {
    const createGoalDto = produce(goal, (draftGoal) => {
      if (draftGoal.id) {
        draftGoal.id = undefined
      }
    })

    const authHeader = await getAuthHeader()
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}${GoalRoute}`, createGoalDto, {
        headers: authHeader,
      })
      .catch((err) => {
        setAlertText(`Unable to create goal.\n\n${err.message}`)
        return null
      })
  }

  const mutateCreateGoal = useMutation(createGoal, {
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.GOALS, 'member']),
    onMutate: async (goal: CreateGoalDto) => {
      await queryClient.cancelQueries(QUERY_KEYS.GOALS)

      const previousData = queryClient.getQueryData<Goal[]>(QUERY_KEYS.GOALS)

      if (previousData) {
        const nextState = produce(previousData, (draft) => {
          draft.push({
            ...goal,
            id: '0',
            created: new Date().toISOString(),
            createdBy: undefined,
          })
        })
        queryClient.setQueryData<Goal[]>(QUERY_KEYS.GOALS, nextState)
      }

      return { previousData }
    },
  })

  const handleCreateGoal = mutateCreateGoal.mutate

  const updateGoalStatus = async (goalStatusDto: UpdateGoalStatusDto) => {
    const authHeader = await getAuthHeader()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${UpdateGoalStatusRoute(
          goalStatusDto.id
        )}`,
        goalStatusDto,
        {
          headers: authHeader,
        }
      )
      .catch((err) => {
        setAlertText(`Unable to update goal.\n\n${err.message}`)
        return null
      })
  }

  const mutateUpdateGoalStatus = useMutation(updateGoalStatus, {
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.GOALS, 'member']),
    onMutate: async (goalStatusDto: UpdateGoalStatusDto) => {
      await queryClient.cancelQueries([QUERY_KEYS.GOALS, 'member'])

      const previousData = queryClient.getQueryData<Goal[]>(QUERY_KEYS.GOALS)

      if (previousData) {
        queryClient.setQueryData<Goal[]>(
          [QUERY_KEYS.GOALS, 'member'],
          previousData.map((goal) => {
            return goal.id === goalStatusDto.id
              ? {
                  ...goal,
                  ...goalStatusDto,
                }
              : goal
          })
        )
      }

      return { previousData }
    },
  })

  const handleUpdateGoalStatus = mutateUpdateGoalStatus.mutate

  const activeGoals = useMemo(
    () => goals.filter((g) => g.status === 'active'),
    [goals]
  )

  const pausedGoals = useMemo(
    () => goals.filter((g) => g.status === 'paused'),
    [goals]
  )

  const completedGoals = useMemo(
    () => goals.filter((g) => g.status === 'complete'),
    [goals]
  )

  const isLoading = useMemo(
    () => isLoadingCategories || isLoadingGoals,
    [isLoadingCategories, isLoadingGoals]
  )

  const error = useMemo(
    () => errorCategories || errorGoals,
    [errorCategories, errorGoals]
  )

  useEffect(() => {
    queryClient.invalidateQueries([QUERY_KEYS.GOALS, 'member'])
  }, [member])

  return {
    isLoading,
    error,
    goals,
    activeGoals,
    pausedGoals,
    completedGoals,
    goalCategories,
    handleSaveGoal,
    handleCreateGoal,
    handleUpdateGoalStatus,
  }
}

export default useGoals
