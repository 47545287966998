import { ResponseValue } from '../hooks/useOnboardingData'

export const getRowValueText = (
  row?: string | number | boolean | ResponseValue | ResponseValue[]
): string | null | undefined => {
  if (!row) {
    return null
  } else if (typeof row === 'object') {
    if ('value' in row) {
      return row['value']
    } else if (row.length) {
      return row.map((r) => r.value).join(', ')
    }
  } else {
    return row as string
  }
}
