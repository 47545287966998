import React, { MouseEventHandler, useEffect, useState } from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button'

import Loader from '../Loader'

import './styles.scss'

export interface SecondaryButtonProps extends ButtonProps {
  isLoading?: boolean
  Icon?: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  className,
  isLoading,
  onClick,
  Icon,
  active,
  ...props
}) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setClicked(false)
    }
  }, [isLoading])

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    setClicked(true)

    if (onClick) {
      onClick(e)
    }
  }

  const buttonContent =
    clicked && isLoading ? (
      <Loader variant="white" />
    ) : (
      <>
        {children}
        {Icon && <Icon className="position-absolute right-4" />}
      </>
    )

  return (
    <Button
      className={'secondary-button position-relative fs-5 ' + className}
      size="lg"
      variant="outline-secondary"
      active={(clicked && isLoading) || active}
      onClick={onClickHandler}
      {...props}
    >
      {buttonContent}
    </Button>
  )
}

export default SecondaryButton
