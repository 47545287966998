import { ReactComponent as ChevronRight } from '../../assets/images/chevron-right.svg'
import { memo, useCallback, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import Select from '../../components/Select'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'

const Timeline: React.FC = () => {
  const {
    nextScreen,
    updateTimeline,
    timeline,
    labsResponse,
    imagingResponse,
  } = usePostAppointmentContext()

  const options = useMemo(() => {
    const moreTestsOrdered = labsResponse || imagingResponse
    const defaultOption = {
      label: 'Select',
      value: '',
    }
    const immediateOption = {
      label: 'On labs/imaging returned',
      value: 'immediately',
    }
    const options = [
      {
        label: '6 weeks',
        value: '6-weeks',
      },
      {
        label: '8 weeks',
        value: '8-weeks',
      },
      {
        label: '3 months',
        value: '3-months',
      },
    ]

    return moreTestsOrdered
      ? [defaultOption, immediateOption, ...options]
      : [defaultOption, ...options]
  }, [labsResponse, imagingResponse])

  const handleNext = useCallback(() => nextScreen(), [])

  return (
    <div className="timeline d-flex justify-content-center align-items-center flex-column">
      <div className="mt-5 max-width">
        <p className="description">
          When would you like to see this patient next?
        </p>
        <p className="note">
          Note: if you ordered tests, we will send the patient reminder 4 weeks
          before the visit.
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-cente select">
        <Select value={timeline} options={options} onChange={updateTimeline} />
      </div>
      <div className="d-flex justify-content-center align-items-center mt-17">
        <PrimaryButton
          onClick={handleNext}
          disabled={!timeline}
          className="button-primary"
        >
          <>Next</>
          <ChevronRight className="space-8" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default memo(Timeline)
