import { Provider } from '../types/types'

export const formatName = (provider: Provider): string => {
  switch (provider.type) {
    case 'Dietician':
      return provider.firstName
    case 'Medical Provider':
      return `${provider.firstName} ${provider.lastName} (${provider.credentials})`
    default:
      return `${provider.firstName} ${provider.lastName}`
  }
}
