import React, { memo, useCallback } from 'react'
import PostAppointmentRouter from './PostAppoinmentRouter'
import { ReactComponent as ChevronLeft } from '../../assets/images/chevron-left.svg'
import './styles.scss'
import {
  PostAppointmentProvider,
  usePostAppointmentContext,
} from '../../context/PostAppointmentContext/PostAppointmentProvider'
import { SourceProvider } from '../../context/SourceContext/SourceProvider'
import { formatMMDDYYYY, formatTime } from '../../utils/time.utils'
import Stepper from './Stepper'

const PostAppointmentNote: React.FC = () => {
  const {
    previousScreen,
    setCurrentScreen,
    updateSelectedNote,
    updateSelectedAppointment,
    currentScreen,
    selectedAppointment,
    selectedNote,
  } = usePostAppointmentContext()

  const handleBack = useCallback(() => {
    if (selectedNote?.status !== 'signed') {
      if (currentScreen === 'tagging') {
        updateSelectedAppointment(undefined)
      }

      previousScreen()
    } else {
      updateSelectedNote(undefined)
      updateSelectedAppointment(undefined)
      setCurrentScreen('initial')
    }
  }, [selectedNote, currentScreen])

  return (
    <div className="pt-3 px-10 post-appointment">
      <div className="d-flex w-100 align-items-center justify-content-between">
        <div className="back">
          {currentScreen !== 'initial' && (
            <div
              className="d-flex align-items-center cursor-pointer mb-2 back"
              onClick={handleBack}
            >
              <ChevronLeft className="nav-icon-small text-primary" />
              <p className="spaced-capital-letters mb-0 text-primary space-8">
                Back
              </p>
            </div>
          )}
        </div>

        <div>
          <p className="h1 mb-2 mt-2 fs-12 text-center title">
            {currentScreen === 'initial'
              ? 'Appointment Notes'
              : selectedAppointment?.type.category}
          </p>
          {currentScreen !== 'initial' && (
            <p className="h1 mb-2 mt-2 fs-12 text-center title">
              {formatMMDDYYYY(new Date(selectedAppointment?.time ?? ''))}{' '}
              {formatTime(new Date(selectedAppointment?.time ?? ''))}
            </p>
          )}
        </div>

        <div className="back-spacer"></div>
      </div>

      <br />
      {currentScreen !== 'initial' && selectedNote?.status !== 'signed' && (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Stepper />
        </div>
      )}
      <br />
      <div>
        <PostAppointmentRouter />
      </div>
    </div>
  )
}

const PostAppointmentPanel = () => {
  return (
    <SourceProvider>
      <PostAppointmentProvider>
        <PostAppointmentNote />
      </PostAppointmentProvider>
    </SourceProvider>
  )
}

export default memo(PostAppointmentPanel)
