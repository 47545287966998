import { Dayjs } from 'dayjs'

export const getNumberOfWeeks = (start: Dayjs, end: Dayjs) => {
  // Calculate the difference in milliseconds
  const diffInMilliseconds = end.diff(start)

  // Convert milliseconds to weeks (1 week = 7 days = 168 hours = 10080 minutes = 604800 seconds = 604800000 milliseconds)
  const diffInWeeks = diffInMilliseconds / (1000 * 60 * 60 * 24 * 7)
  return Number(diffInWeeks.toFixed(0))
}
