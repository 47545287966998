import produce from 'immer'
import React, { useCallback } from 'react'
import { useState } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrimaryButton } from '../../components/Button'
import Select from '../../components/Select'
import { useAlertContext } from '../../context/AlertContext'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useGoals from '../../hooks/useGoals'
import { Goal, GoalAction, GoalCategory } from '../../types/types'

import './styles.scss'

interface GoalRowProps {
  goal: Goal
  index: number
  cancelCreate?: () => void
}

interface GoalEditRowProps extends GoalRowProps {
  isCreate?: boolean
  handleCancel: () => void
}

const MAX_GOAL_LENGTH = 100

const GoalRow: React.FC<GoalRowProps> = ({ goal, index, cancelCreate }) => {
  const { pluginContext } = useSourcePluginContext()
  const { handleSaveGoal } = useGoals(pluginContext?.member ?? undefined)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false)

  const handleShowEditScreen = () => setShowEdit(true)
  const handleHideEditScreen = () => setShowEdit(false)
  const handleShowUpdateStatusScreen = () => setShowUpdateStatus(true)
  const handleHideUpdateStatusScreen = () => setShowUpdateStatus(false)

  const handleCancel = () => {
    handleHideEditScreen()
    handleHideUpdateStatusScreen()
  }

  const handleMarkActive = useCallback(() => {
    const newGoal = produce(goal, (draftGoal) => {
      draftGoal.status = 'active'
      draftGoal.created = undefined
      draftGoal.createdBy = undefined
    })

    handleSaveGoal(newGoal)
  }, [goal])

  return (
    <div className="d-flex justify-content-between mb-4 goal-row">
      {!cancelCreate && !showEdit && !showUpdateStatus && (
        <>
          <div className="flex-grow-1 goal-row-text">
            {index + 1}.{' '}
            {goal.frequencyPerWeek
              ? `You will ${goal.text} ${goal.frequencyPerWeek} times per week`
              : goal.text}
            <strong>({goal.category.name})</strong>
          </div>
          {goal.status !== 'complete' && goal.status !== 'paused' && (
            <div className="d-flex align-items-center justify-content-end goal-row-actions">
              <p
                className="spaced-capital-letters btn-text"
                onClick={handleShowEditScreen}
              >
                Edit
              </p>
              <div className="spacer-0" />
              <p
                className="spaced-capital-letters btn-text"
                onClick={handleShowUpdateStatusScreen}
              >
                Update Status
              </p>
            </div>
          )}
          {goal.status === 'paused' && (
            <div className="d-flex align-items-center justify-content-end goal-row-actions">
              <p
                className="spaced-capital-letters btn-text"
                onClick={handleMarkActive}
              >
                Mark Active
              </p>
            </div>
          )}
        </>
      )}
      {cancelCreate && (
        <GoalEditRow
          goal={goal}
          index={index}
          isCreate={true}
          handleCancel={cancelCreate}
        />
      )}
      {showEdit && (
        <GoalEditRow goal={goal} index={index} handleCancel={handleCancel} />
      )}
      {showUpdateStatus && (
        <GoalUpdateStatusRow
          goal={goal}
          index={index}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}

const GoalEditRow: React.FC<GoalEditRowProps> = ({
  goal,
  index,
  isCreate = false,
  handleCancel,
}) => {
  const { setAlertText } = useAlertContext()
  const { pluginContext } = useSourcePluginContext()
  const { goalCategories, handleSaveGoal, handleCreateGoal } = useGoals(
    pluginContext?.member ?? undefined
  )
  const [text, setText] = useState<string>(goal.text)
  const [frequencyPerWeek, setFrequencyPerWeek] = useState<number | undefined>(
    goal.frequencyPerWeek
  )
  const [category, setCategory] = useState<GoalCategory>(goal.category)

  const options = goalCategories.map((gc) => ({
    value: gc.id.toString(),
    label: gc.name,
  }))

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      setText(event.target.value)
    },
    []
  )

  const handleFrequencyPerWeekChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      let frequency = parseInt(event.target.value)

      if (frequency > 7) {
        // any inputs greater than 7, that is 8 and 9, are set to 7 automatically
        frequency = 7
      } else if (frequency < 1) {
        // any inputs less than 1, that is 0 is set to 1 automatically
        frequency = 1
      }

      if (event.target.value.length <= 1) {
        // inputs of lengths 1 and 0(nothing) is what is allowed, as max value allowed is 7
        setFrequencyPerWeek(frequency)
      }
    },
    []
  )
  const handleCategoryChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const categoryId = parseInt(event.target.value)
      const updatedCategory = goalCategories.find((gc) => gc.id === categoryId)

      if (updatedCategory) {
        setCategory(updatedCategory)
      }
    },
    [goalCategories]
  )

  const handleSave = useCallback(() => {
    if (text.length > MAX_GOAL_LENGTH) {
      setAlertText(
        `Goal must be ${MAX_GOAL_LENGTH} characters or less. Please update to save`
      )
      return
    }

    const newGoal = produce(goal, (draftGoal) => {
      draftGoal.text = text
      draftGoal.category = category
      draftGoal.frequencyPerWeek = frequencyPerWeek

      if (!isCreate) {
        draftGoal.created = undefined
        draftGoal.createdBy = undefined
      }
    })

    isCreate ? handleCreateGoal(newGoal) : handleSaveGoal(newGoal)

    handleCancel()
  }, [goal, text, category, frequencyPerWeek, handleCancel, handleSaveGoal])

  const saveButton = (
    <PrimaryButton
      className="spaced-capital-letters fs-4"
      disabled={category?.id === 0}
      style={{ pointerEvents: category?.id === 0 ? 'none' : 'auto' }}
      onClick={handleSave}
    >
      Save
    </PrimaryButton>
  )

  return (
    <>
      <div className="flex-grow-1 goal-row-text">
        {index + 1}. Goal Details
        <>
          <Form.Control
            value={text}
            onChange={handleTextChange}
            type="text"
            minLength={1}
          />
          <p className="text-end">
            {text.length}/{MAX_GOAL_LENGTH}
          </p>
        </>
        Frequency Per Week
        <p className="fs-2 mb-0">(Minimum Value of 1 and a maximum of 7)</p>
        <Form.Control
          min={1}
          max={7}
          value={frequencyPerWeek}
          onChange={handleFrequencyPerWeekChange}
          type="number"
          className="frequency-per-week"
        />
        <Select
          className="width-fit-content mt-2"
          value={category.id}
          onChange={handleCategoryChange}
          controlId="category"
          options={[
            {
              value: '',
              label: 'Select Category',
            },
            ...options,
          ]}
        />
      </div>

      <div className="d-flex align-items-center justify-content-end goal-row-actions">
        <p
          className="spaced-capital-letters btn-text mb-0"
          onClick={handleCancel}
        >
          Cancel
        </p>
        <div className="spacer-0" />
        {category?.id === 0 ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tool-tip">
                Ensure category is selected
              </Tooltip>
            }
          >
            {saveButton}
          </OverlayTrigger>
        ) : (
          saveButton
        )}
      </div>
    </>
  )
}

const GoalUpdateStatusRow: React.FC<GoalEditRowProps> = ({
  goal,
  index,
  handleCancel,
}) => {
  const { setAlertText } = useAlertContext()
  const { pluginContext } = useSourcePluginContext()
  const { handleUpdateGoalStatus } = useGoals(
    pluginContext?.member ?? undefined
  )
  const [text, setText] = useState<string>(goal.text)
  const [frequencyPerWeek, setFrequencyPerWeek] = useState<number | undefined>(
    goal.frequencyPerWeek
  )
  const [status, setStatus] = useState<GoalAction>('complete')

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      setText(event.target.value)
    },
    []
  )

  const handleFrequencyPerWeekChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      let frequency = parseInt(event.target.value)

      if (frequency > 7) {
        // any inputs greater than 7, that is 8 and 9, are set to 7 automatically
        frequency = 7
      } else if (frequency < 1) {
        // any inputs less than 1, that is 0 is set to 1 automatically
        frequency = 1
      }

      if (event.target.value.length <= 1) {
        // inputs of lengths 1 and 0(nothing) is what is allowed, as max value allowed is 7
        setFrequencyPerWeek(frequency)
      }
    },
    []
  )

  const handleStatusChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setStatus(event.target.value as GoalAction)
    },
    []
  )

  const handleSave = useCallback(() => {
    if (text.length > MAX_GOAL_LENGTH) {
      setAlertText(
        `Goal must be ${MAX_GOAL_LENGTH} characters or less. Please update to save`
      )
      return
    }

    const updateGoalStatusDto = {
      id: goal.id,
      action: status,
      text,
      frequencyPerWeek,
    }

    handleUpdateGoalStatus(updateGoalStatusDto)
    handleCancel()
  }, [
    goal,
    text,
    status,
    frequencyPerWeek,
    handleCancel,
    handleUpdateGoalStatus,
  ])

  return (
    <>
      <div className="flex-grow-1 goal-row-text">
        {index + 1}.
        {status !== 'downgrade' && status !== 'upgrade' && (
          <>
            {goal.frequencyPerWeek
              ? `You will ${goal.text} ${goal.frequencyPerWeek} times per week`
              : goal.text}
            <strong>({goal.category.name})</strong>
          </>
        )}
        {(status === 'downgrade' || status === 'upgrade') && (
          <>
            {index + 1}. Update text to {status} the goal
            <Form.Control
              value={text}
              onChange={handleTextChange}
              type="text"
              minLength={1}
            />
            <p className="text-end">
              {text.length}/{MAX_GOAL_LENGTH}
            </p>
          </>
        )}
        {(status === 'downgrade' || status === 'upgrade') && (
          <>
            Frequency Per Week
            <p className="fs-2 mb-0">(Minimum Value of 1 and a maximum of 7)</p>
            <Form.Control
              min={1}
              max={7}
              value={frequencyPerWeek}
              onChange={handleFrequencyPerWeekChange}
              type="number"
              className="frequency-per-week"
            />
          </>
        )}
        <Select
          className="width-fit-content mt-2"
          value={status}
          onChange={handleStatusChange}
          controlId="status"
          options={[
            { value: 'complete', label: 'Complete' },
            { value: 'pause', label: 'Table (Pause)' },
            { value: 'upgrade', label: 'Upgrade' },
            { value: 'downgrade', label: 'Downgrade' },
          ]}
        />
      </div>

      <div className="d-flex align-items-center justify-content-end goal-row-actions">
        <p
          className="spaced-capital-letters btn-text mb-0"
          onClick={handleCancel}
        >
          Cancel
        </p>
        <div className="spacer-0" />
        <PrimaryButton
          className="spaced-capital-letters fs-4"
          onClick={handleSave}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  )
}

export default GoalRow
