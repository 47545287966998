import { useEffect } from 'react'
import { Patient } from '../types/types'
import { Member as MemberRoute } from '../api/routes'
import { useQuery, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../api/querykeys'
import { useSourcePluginContext } from '../context/SourcePluginContext'
import { useAlertContext } from '../context/AlertContext'

interface UseMemberInterface {
  isLoading: boolean
  error: unknown
  member?: Patient
}

const useMember = (memberId?: string): UseMemberInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { getAuthHeader } = useSourcePluginContext()

  const fetchMember = async (): Promise<Patient | undefined> => {
    if (!memberId) {
      return undefined
    }

    const authHeader = await getAuthHeader()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${MemberRoute(memberId)}`,
      { headers: authHeader }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return undefined
        } else {
          setAlertText(`Error loading member (${res.statusText})`)
          return undefined
        }
      })
      .catch(() => {
        setAlertText(`Error loading member`)
        return undefined
      })
  }

  const {
    isLoading,
    error,
    data: member,
  } = useQuery([QUERY_KEYS.MEMBER, memberId], fetchMember)

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.MEMBER)
  }, [memberId])

  return {
    isLoading,
    error,
    member,
  }
}

export default useMember
