import { useMemo } from 'react'

type CalendarProps = {
  date: Date
  datesFromSlot?: string[]
  currentSelectedDate: Date
  setDate: (date: Date) => void
  setCurrentSelectedDate: React.Dispatch<React.SetStateAction<Date>>
}

const Calendar = ({
  date,
  currentSelectedDate,
  datesFromSlot,
  setCurrentSelectedDate,
}: CalendarProps) => {
  const dates = useMemo(() => {
    // pick the last date of current month
    const lastDateOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    let days = Array.from(
      Array(
        new Date(
          lastDateOfMonth.getFullYear(),
          lastDateOfMonth.getMonth(),
          lastDateOfMonth.getDate()
        ).getDate()
      ).keys()
    ).map((v) => {
      return new Date(date.getFullYear(), date.getMonth(), v + 1)
    })

    days = days.slice(0, days.length)

    const firstItemDay = days[0].getDay()
    const lastItemDay = days[days.length - 1].getDay()
    const endTopUp = 6 - lastItemDay

    if (firstItemDay >= 0) {
      const firstDate = days[0]

      for (let index = 1; index <= firstItemDay; index++) {
        days.unshift(
          new Date(
            firstDate.getFullYear(),
            firstDate.getMonth(),
            firstDate.getDate() - index
          )
        )
      }
    } else {
      const firstDate = days[0]
      // add dates for first two or first day of month that starts on a weekend

      for (let index = 1; index <= 7 + firstItemDay; index++) {
        days.unshift(
          new Date(
            firstDate.getFullYear(),
            firstDate.getMonth(),
            firstDate.getDate() - index
          )
        )
      }
    }

    if (endTopUp >= 0 && endTopUp < 7) {
      const lastDay = days[days.length - 1]

      for (let index = 1; index <= endTopUp; index++) {
        days.push(
          new Date(
            lastDay.getFullYear(),
            lastDay.getMonth(),
            lastDay.getDate() + index
          )
        )
      }
    }

    return days
  }, [date])

  const chipState = (
    state:
      | 'active'
      | 'selected'
      | 'disabled'
      | 'disabled-with-border'
      | 'active-no-border'
  ) => {
    switch (state) {
      case 'active':
        return 'chip chip-with-border'
      case 'selected':
        return 'chip chip-picked text-white'
      case 'disabled':
        return 'chip chip-disabled'
      case 'active-no-border':
        return 'chip chip-no-border'
      case 'disabled-with-border':
        return 'chip chip-with-border chip-disabled'
      default:
        break
    }
  }

  const findState = (
    d: Date
  ):
    | 'active'
    | 'selected'
    | 'disabled'
    | 'disabled-with-border'
    | 'active-no-border' => {
    if (d.toDateString() === currentSelectedDate.toDateString()) {
      return 'selected'
    } else if (d.toDateString() === currentSelectedDate.toDateString()) {
      return 'disabled-with-border'
    } else if (d.getMonth() !== date.getMonth()) {
      return 'disabled'
    } else if (datesFromSlot?.includes(d.toDateString())) {
      return 'active'
    } else if (
      d.getMonth() === date.getMonth() &&
      d.getTime() >= new Date().getTime()
    ) {
      return 'active-no-border'
    } else {
      return 'active-no-border'
    }
  }

  return (
    <div>
      <table>
        <tbody className="d-flex flex-column gap-1">
          <tr>
            {['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].map((value, index) => (
              <th key={index} className="chip chip-no-hover">
                {value}
              </th>
            ))}
          </tr>
          <tr>
            {dates.slice(0, 7).map((d) => {
              const state = findState(d)

              return (
                <td
                  className={chipState(state)}
                  onClick={() => {
                    setCurrentSelectedDate(d)
                  }}
                  key={d.toDateString()}
                >
                  {d.getDate()}
                </td>
              )
            })}
          </tr>
          <tr>
            {dates.slice(7, 14).map((d) => {
              const state = findState(d)

              return (
                <td
                  className={chipState(state)}
                  onClick={() => {
                    setCurrentSelectedDate(d)
                  }}
                  key={d.toDateString()}
                >
                  {d.getDate()}
                </td>
              )
            })}
          </tr>
          <tr>
            {dates.slice(14, 21).map((d) => {
              const state = findState(d)
              return (
                <td
                  className={chipState(state)}
                  onClick={() => {
                    setCurrentSelectedDate(d)
                  }}
                  key={d.toDateString()}
                >
                  {d.getDate()}
                </td>
              )
            })}
          </tr>
          <tr>
            {dates.slice(21, 28).map((d) => {
              const state = findState(d)
              return (
                <td
                  className={chipState(state)}
                  onClick={() => {
                    setCurrentSelectedDate(d)
                  }}
                  key={d.toDateString()}
                >
                  {d.getDate()}
                </td>
              )
            })}
          </tr>
          <tr>
            {dates.slice(28, 35).map((d) => {
              const state = findState(d)
              return (
                <td
                  className={chipState(state)}
                  onClick={() => {
                    setCurrentSelectedDate(d)
                  }}
                  key={d.toDateString()}
                >
                  {d.getDate()}
                </td>
              )
            })}
          </tr>
          {/* to show for months that have 6 rows/weeks*/}
          {dates.length > 35 && (
            <tr>
              {dates.slice(35, 42).map((d) => {
                const state = findState(d)
                return (
                  <td
                    className={chipState(state)}
                    onClick={() => {
                      setCurrentSelectedDate(d)
                    }}
                    key={d.toDateString()}
                  >
                    {d.getDate()}
                  </td>
                )
              })}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

interface ChipInterface {
  state?: string
  onClick?: () => void
  text: string
}

export const Chip: React.FC<ChipInterface> = ({ state, onClick, text }) => {
  return (
    <div
      onClick={onClick}
      className={'d-flex justify-content-center align-items-center ' + state}
    >
      {text}
    </div>
  )
}

export default Calendar
