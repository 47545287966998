import { memo } from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../../components/Loader'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useOnboardingData from '../../hooks/useOnboardingData'
import { getRowValueText } from '../../utils/onboarding.utils'

import './styles.scss'

const fields = [
  { key: 'primaryGoal', label: 'Biggest Concern' },
  { key: 'periodRegularity', label: 'Period Frequency' },
  { key: 'acne', label: 'Acne' },
  { key: 'hairGrowth', label: 'Hair Loss or Excess Growth' },
  { key: 'weight', label: 'How do you feel about your weight?' },
  { key: 'ttc', label: 'TTC' },
  { key: 'hasAnxiety', label: 'Anxiety/Depression' },
  {
    key: 'openToBirthControl',
    label: 'How do you feel about taking birth control to manage PCOS?',
  },
  { key: 'hormonesTested', label: 'Hormones Tested' },
  { key: 'inControl', label: 'How in control of your health are you feeling?' },
  { key: 'howInformed', label: 'How informed are you?' },
  { key: 'conditions', label: 'Previously diagnosed conditions' },
  { key: 'treatments', label: 'Treatments tried: ' },
  { key: 'otherTreatments', label: 'Other treatments tried' },
]

const OnboardingData: React.FC = () => {
  const { pluginContext } = useSourcePluginContext()
  const { onboardingResponse } = useOnboardingData(
    pluginContext?.member ?? undefined
  )

  if (!onboardingResponse) {
    return <Loader />
  }

  return (
    <div className="pt-6 px-4 px-md-6 px-lg-10 onboarding-data-container">
      <p className="h1 mb-4 mt-2 fs-12 text-center">Onboarding Data</p>
      <Table striped bordered hover>
        <tbody>
          {fields.map((row) => {
            const rowValue = (onboardingResponse as any)[row.key] // eslint-disable-line
            const rowValueText = getRowValueText(rowValue)

            if (!rowValue || !rowValueText) {
              return <></>
            }

            return (
              <tr key={row.key}>
                <td className="font-weight-bold">{row.label}</td>
                <td>{rowValueText}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default memo(OnboardingData)
