import { Dayjs } from 'dayjs'
import { useState, useCallback, useMemo } from 'react'
import { OpenCareGoalLogsDto, Goal } from '../../types/types'
import { getNumberOfWeeks } from '../../utils/date.utils'
import { ReactComponent as FirstPercentileGuage } from '../../assets/images/first-percentile.svg'
import { ReactComponent as SecondPercentileGuage } from '../../assets/images/second-percentile.svg'
import { ReactComponent as ThirdPercentileGauge } from '../../assets/images/third-percentile.svg'
import { ReactComponent as FourthPercentileGauge } from '../../assets/images/fourth-percentile.svg'

type GoalItemProps = {
  goalLogs: OpenCareGoalLogsDto[]
  goal: Goal
  date: Date | null
  startDate: Dayjs
  endDate: Dayjs
}

const GoalItem = ({ goal, goalLogs, startDate, endDate }: GoalItemProps) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const handleShowCalendar = useCallback(() => {
    setShowCalendar(!showCalendar)
  }, [showCalendar])

  const weeks = useMemo(() => {
    return getNumberOfWeeks(startDate, endDate)
  }, [startDate, endDate])

  const goalStats = useMemo(() => {
    const total = (goal.frequencyPerWeek ?? 0) * weeks
    const completed = goalLogs.length
    const percentComplete = total === 0 ? 0 : (completed / total) * 100
    let icon

    if (percentComplete > 75) {
      icon = <FourthPercentileGauge />
    } else if (percentComplete > 50) {
      icon = <ThirdPercentileGauge />
    } else if (percentComplete > 25) {
      icon = <SecondPercentileGuage />
    } else {
      icon = <FirstPercentileGuage />
    }

    return {
      percentComplete:
        percentComplete > 100 ? '100+%' : `${percentComplete.toFixed(0)}%`,
      fractionComplete:
        completed > total ? `${total}/${total}` : `${completed}/${total}`,
      icon: icon,
    }
  }, [goal, goalLogs, weeks])

  return (
    <div className="d-flex flex-column w-75 goal-item">
      <div
        onClick={handleShowCalendar}
        className="d-flex align-items-center gap-5 goal-item-cursor"
      >
        <p className="w-50">
          {goal.frequencyPerWeek
            ? `${goal.text} ${goal.frequencyPerWeek} times per week`
            : goal.text}
        </p>
        <div className="d-flex align-items-center gap-1">
          <div className="d-flex flex-column align-items-center">
            {goalStats.icon}
            <p>{goalStats.fractionComplete}</p>
          </div>
          <p>
            <b>{goalStats.percentComplete}</b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default GoalItem
