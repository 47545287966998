"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceBridge = void 0;
const SourceBridgeClient_1 = require("./SourceBridgeClient");
const generateRequestId_1 = require("./generateRequestId");
class SourceBridgeAPI {
    constructor() {
        this.onContextCallbacks = [];
        this.client = new SourceBridgeClient_1.SourceBridgeClient();
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.client.sendRequest({
                type: 'hello',
                id: (0, generateRequestId_1.generateRequestId)(),
            });
            this.handleNewAuth(response.payload.auth);
            const info = this.handlePluginInfo(response.payload.plugin_info);
            // Call the context callback with the initial context
            yield this.handleNewContext(response.payload.context);
            // Subscribe to any further context events
            this.client.onEvent('context', (envelope) => __awaiter(this, void 0, void 0, function* () {
                const context = envelope.payload;
                yield this.handleNewContext(context);
            }));
            // Subscribe to any auth events
            // eslint-disable-next-line @typescript-eslint/require-await
            this.client.onEvent('authentication', (envelope) => __awaiter(this, void 0, void 0, function* () {
                this.handleNewAuth(envelope.payload);
            }));
            return info;
        });
    }
    ready() {
        this.client.sendEvent({
            type: 'ready',
            id: (0, generateRequestId_1.generateRequestId)(),
        });
    }
    // This is async because we intend to add 'fetch on demand' when the current token is
    // expired (e.g. if the tab has been throttled by the browser, or the computer has been
    // suspended.)
    // eslint-disable-next-line @typescript-eslint/require-await
    currentToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.auth) {
                console.error('[SourceBridge] called currentToken() before init().');
                throw new Error('SourceBridge is not yet initialized. Please call `init()` before currentToken()');
            }
            return this.auth;
        });
    }
    currentContext() {
        if (!this.context) {
            console.error('[SourceBridge] called currentContext() before init().');
            throw new Error('SourceBridge is not yet initialized. Please call `init()` before currentContext()');
        }
        return this.context;
    }
    info() {
        if (!this.pluginInfo) {
            console.error('[SourceBridge] called info() before init().');
            throw new Error('SourceBridge is not yet initialized. Please call `init()` before info()');
        }
        return this.pluginInfo;
    }
    onContextUpdate(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onContextCallbacks.push(callback);
            // We immediately call the callback if we already have received context, in case
            // the developer forgot to set up the callbacks before running init()
            if (this.context) {
                yield callback(this.context);
            }
        });
    }
    handleNewContext(context) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('[SourceBridge] handling new context: ', context);
            this.context = context;
            yield Promise.allSettled(this.onContextCallbacks.map((callback) => callback(context)));
        });
    }
    handleNewAuth(auth) {
        console.log('[SourceBridge] handling new application token.');
        if (!auth.token || !auth.expires_at) {
            console.error('[SourceBridge] could not parse new application token');
            return;
        }
        this.auth = {
            token: auth.token,
            expiresAt: new Date(auth.expires_at),
        };
    }
    handlePluginInfo(info) {
        const mapped = {
            application: info.application,
            viewKey: info.view_key,
            surface: info.surface,
        };
        this.pluginInfo = mapped;
        return mapped;
    }
}
exports.SourceBridge = new SourceBridgeAPI();
