import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import {
  AppointmentNotes as AppointmentNotesRoute,
  SaveAppointmentNotes as SaveAppointmentNotesRoute,
} from '../api/routes'
import { QUERY_KEYS } from '../api/querykeys'
import { AppointmentNote } from '../types/types'
import { useSourcePluginContext } from '../context/SourcePluginContext'
import { useAlertContext } from '../context/AlertContext'
import produce from 'immer'

interface SaveNoteDto {
  note: AppointmentNote
  files?: FileList[]
}

interface UseAppointmentNotesInterface {
  isLoading: boolean
  error: unknown
  notes: AppointmentNote[]
  handleSaveNote: UseMutateAsyncFunction<
    AxiosResponse<string> | null,
    unknown,
    SaveNoteDto,
    unknown
  >
}

const useAppointmentNotes = (member?: string): UseAppointmentNotesInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { getAuthHeader } = useSourcePluginContext()

  const fetchAppointmentNotes = async (): Promise<AppointmentNote[]> => {
    if (!member) {
      return []
    }

    const authHeader = await getAuthHeader()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentNotesRoute(member)}`,
      { headers: authHeader }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return []
        } else {
          setAlertText(`Error loading appointment notes (${res.statusText})`)
          return []
        }
      })
      .catch(() => {
        setAlertText(`Error loading appointment notes`)
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    [QUERY_KEYS.APPOINTMENT_NOTES, member ?? 'default'],
    fetchAppointmentNotes
  )

  const notes = useMemo(() => data ?? [], [data])

  const updateNote = async ({ note, files }: SaveNoteDto) => {
    const authHeader = await getAuthHeader()

    const formData = new FormData()

    if (files) {
      files.forEach((fileList) => {
        Array.from(fileList).forEach((file) => {
          formData.append('files', file)
        })
      })
    }

    formData.append('note', JSON.stringify(note))

    return axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}${SaveAppointmentNotesRoute(
          note.id
        )}`,
        formData,
        { headers: authHeader }
      )
      .catch((err) => {
        setAlertText(`Unable to save note.\n\n${err.message}`)
        return null
      })
  }

  const mutateUpdateNote = useMutation(updateNote, {
    onSettled: () =>
      queryClient.invalidateQueries(QUERY_KEYS.APPOINTMENT_NOTES),
    onMutate: async ({ note, files }: SaveNoteDto) => {
      await queryClient.cancelQueries(QUERY_KEYS.APPOINTMENT_NOTES)

      const previousData = queryClient.getQueryData<AppointmentNote[]>(
        QUERY_KEYS.APPOINTMENT_NOTES
      )

      if (previousData) {
        const nextState = produce(previousData, (draft) => {
          const index = draft.findIndex((note) => note.id === note.id)
          draft[index] = {
            ...note,
            attachments: files?.length
              ? files.flatMap((fileList) =>
                  Array.from(fileList).map((file, i) => ({
                    id: String(i),
                    fileId: String(i),
                    fileName: file.name,
                  }))
                )
              : undefined,
          }
        })
        queryClient.setQueryData<AppointmentNote[]>(
          QUERY_KEYS.APPOINTMENT_NOTES,
          nextState
        )
      }

      return { previousData }
    },
  })

  const handleSaveNote = mutateUpdateNote.mutateAsync

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.APPOINTMENT_NOTES)
  }, [member])

  return {
    isLoading,
    error,
    notes,
    handleSaveNote,
  }
}

export default useAppointmentNotes
