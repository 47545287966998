"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceBridgeClient = void 0;
class SourceBridgeClient {
    constructor() {
        // Map where we keep track of open requests and their promise resolve callbacks
        this.openRequests = new Map();
        // Map where we keep track of event subscriptions
        this.messageCallbacks = new Map();
        window.addEventListener('message', (event) => {
            // Because we allow async callbacks, handleEnvelope is async, but addEventListener only takes non-async callbacks
            // so we use `void` operator to allow using an async function here.
            void this.handleEnvelope(event);
        });
    }
    sendEvent(message) {
        console.log('[SourceBridge] sending message to parent: ', message);
        parent.postMessage(JSON.stringify(message), '*');
    }
    sendRequest(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const promise = new Promise((resolve, _reject) => {
                this.openRequests.set(message.id, resolve);
            });
            console.log('[SourceBridge] sending request to parent: ', message);
            parent.postMessage(JSON.stringify(message), '*');
            return promise;
        });
    }
    onEvent(type, callback) {
        const callbacks = this.messageCallbacks.get(type);
        if (callbacks) {
            callbacks.push(callback);
        }
        else {
            this.messageCallbacks.set(type, [callback]);
        }
    }
    handleEnvelope(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (event.source !== parent) {
                console.log('[SourceBridge] Ignoring message event from non-parent.');
                return;
            }
            const envelope = this.parseEnvelope(event.data);
            if (!envelope) {
                return;
            }
            // If we have in_reply_to set then this is a response
            if (envelope.in_reply_to) {
                this.handleResponse(envelope);
            }
            else {
                yield this.handleEvent(envelope);
            }
        });
    }
    handleEvent(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const callbacks = this.messageCallbacks.get(message.type);
            if (callbacks) {
                for (const callback of callbacks) {
                    try {
                        yield callback(message);
                    }
                    catch (error) {
                        console.error('[SourceBridge] error thrown in event callback: ', error);
                    }
                }
            }
        });
    }
    handleResponse(message) {
        const requestId = message.in_reply_to;
        console.log(`[SourceBridge] handling response to ${requestId}`, message);
        const resolve = this.openRequests.get(requestId);
        if (!resolve) {
            console.error(`[SourceBridge] Did not find resolve for request ${requestId}.`);
            return;
        }
        this.openRequests.delete(requestId);
        resolve(message);
    }
    parseEnvelope(data) {
        try {
            const envelope = JSON.parse(data);
            if (envelope.id && envelope.type) {
                return envelope;
            }
            console.error('[SourceBridge] received non-Source-envelope message: ', envelope);
            return null;
        }
        catch (err) {
            console.error('[SourceBridge] received non-JSON message: ', data, err);
            return null;
        }
    }
}
exports.SourceBridgeClient = SourceBridgeClient;
