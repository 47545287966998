import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../api/querykeys'
import { Onboarding as OnboardingRoute } from '../api/routes'
import { useAlertContext } from '../context/AlertContext'
import { useSourcePluginContext } from '../context/SourcePluginContext'

export type ResponseValue = { value: string }

export interface OnboardingResponse {
  waitlistUserId: string
  insurance?: boolean
  inControl?: number
  primaryGoal?: ResponseValue
  periodRegularity?: ResponseValue
  acne?: ResponseValue
  hairGrowth?: ResponseValue
  weight?: ResponseValue
  ttc?: ResponseValue
  hasAnexiety?: ResponseValue
  openToBirthControl?: ResponseValue
  hormonesTested?: ResponseValue
  howInformed?: ResponseValue
  conditions?: ResponseValue[]
  treatments?: ResponseValue[]
  otherTreatments?: string
}

export interface UseOnboardingDataInterface {
  onboardingResponse?: OnboardingResponse
}

const useOnboardingData = (memberId?: string): UseOnboardingDataInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { getAuthHeader } = useSourcePluginContext()

  const fetchResponse = async () => {
    if (!memberId) {
      return undefined
    }

    const authHeader = await getAuthHeader()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${OnboardingRoute(memberId)}`,
      {
        headers: authHeader,
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return undefined
        } else {
          setAlertText(`Error loading onboarding data (${res.statusText})`)
          return undefined
        }
      })
      .catch(() => {
        setAlertText(`Error loading onboarding data`)
        return undefined
      })
  }

  const { data: onboardingResponse } = useQuery(
    [QUERY_KEYS.ONBOARDING, memberId ?? 'default'],
    fetchResponse,
    {
      staleTime: Infinity,
    }
  )

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.ONBOARDING)
  }, [memberId])

  return { onboardingResponse }
}

export default useOnboardingData
