import { memo, useCallback, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import { ReactComponent as ChevronRight } from '../../assets/images/chevron-right.svg'
import './styles.scss'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'

const Tagging: React.FC = () => {
  const { sourceTags, selectedTags, memberTags, updateTags, nextScreen } =
    usePostAppointmentContext()

  const handleNext = useCallback(() => nextScreen(), [])

  const selectedTagIds = useMemo(
    () => selectedTags.map((t) => t.id),
    [selectedTags]
  )

  const memberTagsIds = useMemo(() => memberTags.map((t) => t.id), [memberTags])

  return (
    <div className="tagging d-flex justify-content-center flex-column">
      <div className="tags">
        <p className="sub-title mb-5">Tags</p>
        <div className="d-flex gap-4 flex-wrap">
          {sourceTags.map((item, index) => (
            <div
              key={index}
              className={`select-option ${
                memberTagsIds.includes(item.id) &&
                selectedTagIds.includes(item.id)
                  ? 'select-option-member'
                  : selectedTagIds.includes(item.id)
                  ? 'select-option-selected'
                  : ''
              }`}
              onClick={() => {
                updateTags(item)
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
        {/*Other tags taken off for the time being*/}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-16">
        <PrimaryButton onClick={handleNext} className="button-primary">
          <>Next</>
          <ChevronRight className="space-8" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default memo(Tagging)
