import {
  NextScreenContext,
  PostAppointmentScreen,
  PostAppointmentScreenStepper,
} from '../context/PostAppointmentContext/PostAppointmentProvider'
import { AppointmentType } from '../types/types'

export const APPOINTMENT_CATEGORY = {
  INITIAL_MD: 'Initial MD',
  SECOND_MD: 'Second MD',
  FOLLOWUP_MD: 'Followup MD',
  CARE_TRANSFER_MD: 'Care Transfer MD',
  DIAGNOSTIC: 'Diagnostic',
  INITIAL_HMT: 'Initial HMT',
  INITIAL_RD: 'Initial RD',
  FOLLOWUP_RD: 'Followup RD',
  INITIAL_NUTRITION_ONLY: 'Initial Nutrition Only',
  FOLLOWUP_NUTRITION_ONLY: 'Followup Nutrition Only',
}

export const listScreens = (
  appointmentType: AppointmentType
): PostAppointmentScreenStepper[] => {
  switch (appointmentType.category) {
    case APPOINTMENT_CATEGORY.INITIAL_MD:
    case APPOINTMENT_CATEGORY.SECOND_MD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_MD:
    case APPOINTMENT_CATEGORY.CARE_TRANSFER_MD:
      return mdStepper
    case APPOINTMENT_CATEGORY.DIAGNOSTIC:
      return dxStepper
    case APPOINTMENT_CATEGORY.INITIAL_HMT:
      return initialHMTStepper
    case APPOINTMENT_CATEGORY.INITIAL_RD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_RD:
    case APPOINTMENT_CATEGORY.INITIAL_NUTRITION_ONLY:
    case APPOINTMENT_CATEGORY.FOLLOWUP_NUTRITION_ONLY:
    default:
      return rdStepper
  }
}

export const nextScreen = (
  currentScreen: PostAppointmentScreen,
  appointmentType: AppointmentType,
  context?: NextScreenContext
) => {
  switch (appointmentType.category) {
    case APPOINTMENT_CATEGORY.INITIAL_MD:
      return initialMDNextScreen(currentScreen, context)
    case APPOINTMENT_CATEGORY.SECOND_MD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_MD:
    case APPOINTMENT_CATEGORY.CARE_TRANSFER_MD:
      return followupMDNextScreen(currentScreen, context)
    case APPOINTMENT_CATEGORY.DIAGNOSTIC:
      return diagnosticNextScreen(currentScreen)
    case APPOINTMENT_CATEGORY.INITIAL_HMT:
      return initialHMTNextScreen(currentScreen)
    case APPOINTMENT_CATEGORY.INITIAL_RD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_RD:
    case APPOINTMENT_CATEGORY.INITIAL_NUTRITION_ONLY:
    case APPOINTMENT_CATEGORY.FOLLOWUP_NUTRITION_ONLY:
    default:
      return nutritionNextScreen(currentScreen)
  }
}

export const previousScreen = (
  currentScreen: PostAppointmentScreen,
  appointmentType: AppointmentType,
  context?: NextScreenContext
) => {
  switch (appointmentType.category) {
    case APPOINTMENT_CATEGORY.INITIAL_MD:
      return initialMDPreviousScreen(currentScreen, context)
    case APPOINTMENT_CATEGORY.SECOND_MD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_MD:
    case APPOINTMENT_CATEGORY.CARE_TRANSFER_MD:
      return followupMDPreviousScreen(currentScreen, context)
    case APPOINTMENT_CATEGORY.DIAGNOSTIC:
      return diagnosticPreviousScreen(currentScreen)
    case APPOINTMENT_CATEGORY.INITIAL_HMT:
      return initialHMTPreviousScreen(currentScreen)
    case APPOINTMENT_CATEGORY.INITIAL_RD:
    case APPOINTMENT_CATEGORY.FOLLOWUP_RD:
    case APPOINTMENT_CATEGORY.INITIAL_NUTRITION_ONLY:
    case APPOINTMENT_CATEGORY.FOLLOWUP_NUTRITION_ONLY:
    default:
      return nutritionPreviousScreen(currentScreen)
  }
}

const initialMDNextScreen = (
  currentScreen: PostAppointmentScreen,
  context?: NextScreenContext
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'initial':
      return 'tagging'
    case 'tagging':
      return 'tests'
    case 'tests':
      return context?.moreTestsOrdered || context?.noFollowUp
        ? 'note'
        : 'timeline'
    case 'timeline':
      return 'note'
    case 'note':
      return 'review'
    case 'review':
    default:
      return 'initial'
  }
}

const followupMDNextScreen = (
  currentScreen: PostAppointmentScreen,
  context?: NextScreenContext
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'initial':
      return 'tagging'
    case 'tagging':
      return 'tests'
    case 'tests':
      return context?.noFollowUp ? 'note' : 'timeline'
    case 'timeline':
      return 'note'
    case 'note':
      return 'review'
    case 'review':
    default:
      return 'initial'
  }
}

const initialHMTNextScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'initial':
      return 'tagging'
    case 'tagging':
      return 'tests'
    case 'tests':
      return 'note'
    case 'note':
      return 'review'
    case 'review':
    default:
      return 'initial'
  }
}

const diagnosticNextScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'initial':
      return 'tagging'
    case 'tagging':
      return 'tests'
    case 'tests':
      return 'recommended-program'
    case 'recommended-program':
      return 'note'
    case 'note':
      return 'review'
    case 'review':
    default:
      return 'initial'
  }
}

const nutritionNextScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'initial':
      return 'note'
    case 'note':
      return 'review'
    case 'review':
    default:
      return 'initial'
  }
}

const initialMDPreviousScreen = (
  currentScreen: PostAppointmentScreen,
  context?: NextScreenContext
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'tagging':
      return 'initial'
    case 'tests':
      return 'tagging'
    case 'timeline':
      return 'tests'
    case 'note':
      return context?.moreTestsOrdered || context?.noFollowUp
        ? 'tests'
        : 'timeline'
    case 'review':
      return 'note'
    default:
      return 'initial'
  }
}

const followupMDPreviousScreen = (
  currentScreen: PostAppointmentScreen,
  context?: NextScreenContext
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'tagging':
      return 'initial'
    case 'tests':
      return 'tagging'
    case 'timeline':
      return 'tests'
    case 'note':
      return context?.moreTestsOrdered || context?.noFollowUp
        ? 'tests'
        : 'timeline'
    case 'review':
      return 'note'
    default:
      return 'initial'
  }
}

const initialHMTPreviousScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'tagging':
      return 'initial'
    case 'tests':
      return 'tagging'
    case 'note':
      return 'tests'
    case 'review':
      return 'note'
    default:
      return 'initial'
  }
}

const diagnosticPreviousScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'tagging':
      return 'initial'
    case 'tests':
      return 'tagging'
    case 'recommended-program':
      return 'tests'
    case 'note':
      return 'recommended-program'
    case 'review':
      return 'note'
    default:
      return 'initial'
  }
}

const nutritionPreviousScreen = (
  currentScreen: PostAppointmentScreen
): PostAppointmentScreen => {
  switch (currentScreen) {
    case 'note':
      return 'initial'
    case 'review':
      return 'note'
    default:
      return 'initial'
  }
}

const mdStepper: PostAppointmentScreenStepper[] = [
  {
    index: 1,
    title: 'Tagging',
    screen: 'tagging',
  },
  {
    index: 2,
    title: 'Tests',
    screen: 'tests',
  },
  {
    index: 3,
    title: 'Timeline',
    screen: 'timeline',
  },
  {
    index: 4,
    title: 'Note',
    screen: 'note',
  },
  {
    index: 5,
    title: 'Review',
    screen: 'review',
  },
]

const rdStepper: PostAppointmentScreenStepper[] = [
  {
    index: 1,
    title: 'Note',
    screen: 'note',
  },
  {
    index: 2,
    title: 'Review',
    screen: 'review',
  },
]

const initialHMTStepper: PostAppointmentScreenStepper[] = [
  {
    index: 1,
    title: 'Tagging',
    screen: 'tagging',
  },
  {
    index: 2,
    title: 'Tests',
    screen: 'tests',
  },
  {
    index: 3,
    title: 'Note',
    screen: 'note',
  },
  {
    index: 4,
    title: 'Review',
    screen: 'review',
  },
]

const dxStepper: PostAppointmentScreenStepper[] = [
  {
    index: 1,
    title: 'Tagging',
    screen: 'tagging',
  },
  {
    index: 2,
    title: 'Tests',
    screen: 'tests',
  },
  {
    index: 3,
    title: 'Program',
    screen: 'recommended-program',
  },
  {
    index: 4,
    title: 'Note',
    screen: 'note',
  },
  {
    index: 5,
    title: 'Review',
    screen: 'review',
  },
]
