import React, { createContext, ReactNode } from 'react'
import { useState } from 'react'
import { memo } from 'react'
import ConfirmDialog from '../../components/ConfirmDialog'

export interface AlertContextInterface {
  setAlertText: (text: string) => void
}

export const AlertContext = createContext<AlertContextInterface>(
  {} as AlertContextInterface
)

interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = memo(
  ({ children }) => {
    const [text, setText] = useState<string | null>(null)

    const handleCloseDialog = () => setText(null)

    return (
      <AlertContext.Provider value={{ setAlertText: setText }}>
        {children}
        <ConfirmDialog
          open={text !== null}
          text={text ?? ''}
          titleText="An Error Occured"
          handleClose={handleCloseDialog}
        />
      </AlertContext.Provider>
    )
  }
)
