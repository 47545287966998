import { memo, useCallback, useMemo, useRef } from 'react'
import { PrimaryButton } from '../../components/Button'
import { ReactComponent as ChevronRight } from '../../assets/images/chevron-right.svg'
import { Form } from 'react-bootstrap'
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'

import './styles.scss'
import { Close } from '@material-ui/icons'

const Note: React.FC = () => {
  const {
    note,
    selectedFiles,
    handleAttachments,
    handleRemoveAttachment,
    updateNote,
    nextScreen,
  } = usePostAppointmentContext()
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleNext = useCallback(() => nextScreen(), [])

  const selectedFilesRemapped = useMemo(
    () => selectedFiles.map((file) => [...Array.from(file)]).flat(),
    [selectedFiles]
  )

  return (
    <div className="note d-flex align-items-center justify-content-center flex-column">
      <div className="container mt-5">
        <p className="label">Please leave a visit note for the patient:</p>
        <Form.Control
          as="textarea"
          value={note}
          onChange={updateNote}
          type="text"
          rows={10}
        />
        <div className="d-flex justify-content-end align-items-end mt-3 flex-column">
          <input
            type="file"
            className="file-input"
            multiple
            accept="application/pdf, image/png, image/jpeg, image/gif"
            ref={hiddenFileInput}
            onChange={handleAttachments}
            id="choose-file-input"
          ></input>
          <label htmlFor="choose-file-input">
            <div className=" attachment">+ Add attachment</div>
          </label>
          <div>
            {selectedFilesRemapped &&
              selectedFilesRemapped.map((file, index) => (
                <div
                  className="text-decoration-underline d-flex align-items-center justify-content-center gap-2"
                  key={index}
                >
                  <CheckIcon />
                  <div>{file.name}</div>
                  <Close
                    className="pointer"
                    onClick={() => {
                      handleRemoveAttachment(index)
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="mt-9">
        <PrimaryButton
          onClick={handleNext}
          disabled={!note}
          className="button-primary"
        >
          <>Next</>
          <ChevronRight />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default memo(Note)
