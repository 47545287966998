import { memo } from 'react'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'
import Note from './Note'
import Review from './Review'
import Tagging from './Tagging'
import Tests from './Tests'
import Timeline from './Timeline'
import Initial from './Initial'
import RecommendedProgram from './RecommendedProgram'

const PostAppointmentRouter: React.FC = () => {
  const { currentScreen } = usePostAppointmentContext()

  switch (currentScreen) {
    case 'initial':
      return <Initial />
    case 'tagging':
      return <Tagging />
    case 'recommended-program':
      return <RecommendedProgram />
    case 'tests':
      return <Tests />
    case 'timeline':
      return <Timeline />
    case 'note':
      return <Note />
    case 'review':
      return <Review />
    default:
      return <Tagging />
  }
}

export default memo(PostAppointmentRouter)
