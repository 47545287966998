import { MouseEventHandler, useEffect, useState } from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button'

import Loader from '../Loader'

interface PrimaryButtonProps extends ButtonProps {
  isLoading?: boolean
  loaderSize?: 'sm' | 'md'
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  className,
  isLoading,
  onClick,
  disabled,
  loaderSize,
  ...props
}) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setClicked(false)
    }
  }, [isLoading])

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    setClicked(true)

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Button
      className={'position-relative ' + className}
      variant="primary"
      size="lg"
      disabled={disabled || (clicked && isLoading)}
      onClick={onClickHandler}
      {...props}
    >
      {clicked && isLoading ? <Loader variant={loaderSize} /> : children}
    </Button>
  )
}

export default PrimaryButton
