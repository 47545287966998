import { Appointment } from '../types/types'

export const getAppointmentType = (
  appointment: Appointment
): 'diagnostic' | 'full-program-medical' | 'nutrition' | undefined => {
  if (appointment.type.category.toLowerCase().includes('diagnostic')) {
    return 'diagnostic'
  } else if (appointment.type.category.toLowerCase().includes('md')) {
    return 'full-program-medical'
  } else if (
    appointment.type.category.toLowerCase().includes('nutrition') ||
    appointment.type.category.toLowerCase().includes('rd')
  ) {
    return 'nutrition'
  } else {
    return undefined
  }
}
