import dayjs, { Dayjs } from 'dayjs'
import { useState, useCallback, useMemo } from 'react'
import Calendar from './Calendar'
import { OpenCareGoalLogsWithGoal, OpenCareGoal } from '../../types/types'

type CalendarViewProps = {
  goalLogs?: OpenCareGoalLogsWithGoal
  setDateToUse: (date: Dayjs) => void
  dateToUse: Dayjs
  openCareGoals: { [goalId: string]: OpenCareGoal }
}

const CalenderView = ({
  goalLogs,
  dateToUse,
  openCareGoals,
  setDateToUse,
}: CalendarViewProps) => {
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date>(
    new Date()
  )
  const handleMonthly = useCallback(
    (type: 'next' | 'prev') => {
      setDateToUse(dateToUse.add(type === 'next' ? 1 : -1, 'month'))
      setCurrentSelectedDate(
        dateToUse.add(type === 'next' ? 1 : -1, 'month').toDate()
      )
    },
    [dateToUse]
  )

  const goalsLogsDates = useMemo(() => {
    const dates = goalLogs?.logs?.map((logs) =>
      new Date(logs.activityDateTime).toDateString()
    )
    return Array.from(new Set(dates))
  }, [goalLogs])

  const goalsLogsFiltered = useMemo(() => {
    return goalLogs?.logs?.filter(
      (log) =>
        new Date(log.activityDateTime).toDateString() ===
        currentSelectedDate.toDateString()
    )
  }, [goalLogs, currentSelectedDate])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-4 align-self-center">
        <p onClick={() => handleMonthly('prev')} className="goal-item-cursor">
          <b>{'<'}</b>
        </p>
        <p>{dateToUse.format('MMMM YYYY')}</p>
        <p onClick={() => handleMonthly('next')} className="goal-item-cursor">
          <b>{'>'}</b>
        </p>
      </div>
      <div>
        <Calendar
          currentSelectedDate={currentSelectedDate}
          setCurrentSelectedDate={setCurrentSelectedDate}
          setDate={(d) => {
            setDateToUse(dayjs(d))
          }}
          datesFromSlot={goalsLogsDates}
          date={dateToUse.toDate()}
        />
        <div className="mt-3">
          <p>
            Completed on {dayjs(currentSelectedDate).format('MMM DD, YYYY')}
          </p>
          <ul>
            {goalsLogsFiltered?.map((log) => {
              const goal = openCareGoals[log.careTaskId]
              return <li key={log.id}>{goal?.description}</li>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CalenderView
