import { QueryKey, UseQueryOptions, useQueries, useQuery } from 'react-query'
import { OpenCareGoalLogs, OpenCareGoals } from '../api/routes'
import { useSourcePluginContext } from '../context/SourcePluginContext'
import {
  Goal,
  OpenCareGoal,
  OpenCareGoalLogsDto,
  OpenCareGoalLogsWithGoal,
} from '../types/types'
import { Dayjs } from 'dayjs'
import { QUERY_KEYS } from '../api/querykeys'
import { useMemo } from 'react'

type UseGoalOverviewInterface = {
  goalsLogs: OpenCareGoalLogsWithGoal
  overViewGoalsLogs: {
    [goalId: string]: OpenCareGoalLogsDto[]
  }
  openCareGoals: {
    [goalId: string]: OpenCareGoal
  }
}

const useGoalOverview = (
  member: string,
  calendarDateToUse: Dayjs,
  startDate: Dayjs,
  endDate: Dayjs,
  goalsFiltered: Goal[],
  checked: boolean
): UseGoalOverviewInterface => {
  const { getAuthHeader } = useSourcePluginContext()

  const fetchGoalLogs = async (
    start: string,
    end: string,
    goal?: Goal
  ): Promise<OpenCareGoalLogsWithGoal> => {
    const authHeader = await getAuthHeader()

    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${OpenCareGoalLogs(
        member ?? '',
        start,
        end,
        goal?.id
      )}`,
      {
        headers: authHeader,
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          const goalLogs = data as OpenCareGoalLogsDto[]

          const value = {
            goal: goal,
            logs: goalLogs.filter((log) => log.completed),
          } as OpenCareGoalLogsWithGoal

          return value
        } else {
          return {} as OpenCareGoalLogsWithGoal
        }
      })
      .catch(() => {
        return {} as OpenCareGoalLogsWithGoal
      })
  }

  const fetchOpenCareGoals = async (
    careTaskId: string
  ): Promise<OpenCareGoal> => {
    const authHeader = await getAuthHeader()

    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${OpenCareGoals(careTaskId)}`,
      {
        headers: authHeader,
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data as OpenCareGoal
        } else {
          return {} as OpenCareGoal
        }
      })
      .catch(() => {
        return {} as OpenCareGoal
      })
  }

  const { data } = useQuery(
    [
      QUERY_KEYS.GOALSLOGS,
      calendarDateToUse?.startOf('month').toISOString(),
      calendarDateToUse?.endOf('month').toISOString(),
      member,
    ],
    () =>
      fetchGoalLogs(
        calendarDateToUse?.startOf('month').toISOString(),
        calendarDateToUse?.endOf('month').toISOString()
      )
  )

  const goalsLogs = useMemo(
    () => data ?? ({} as OpenCareGoalLogsWithGoal),
    [data]
  )

  const goalsLogsPromises:
    | UseQueryOptions<
        OpenCareGoalLogsWithGoal,
        unknown,
        OpenCareGoalLogsWithGoal,
        QueryKey
      >[]
    | [] = useMemo(() => {
    if (goalsFiltered.length > 0) {
      const goalLogsQuery = goalsFiltered.map((goal) => {
        return {
          queryKey: [
            QUERY_KEYS.GOALSLOGS,
            goal.id,
            startDate.toISOString(),
            endDate.toISOString(),
          ],
          queryFn: () =>
            fetchGoalLogs(startDate.toISOString(), endDate.toISOString(), goal),
          enabled: checked,
        }
      })

      return goalLogsQuery
    }

    return []
  }, [goalsFiltered, goalsFiltered.length, startDate, endDate, checked])

  const queries = useQueries(goalsLogsPromises)

  const overViewGoalsLogs = useMemo(() => {
    if (queries.length > 0) {
      return queries.reduce(
        (
          accumulator: { [goalId: string]: OpenCareGoalLogsDto[] },
          currentValue
        ) => {
          const goalLog = currentValue.data?.logs ?? []
          const goalId = currentValue.data?.goal?.id ?? ''

          if (!accumulator[goalId]) {
            accumulator[goalId] = []
          }

          accumulator[goalId] = goalLog
          return accumulator
        },
        {}
      )
    }

    return {}
  }, [queries])

  const openCareGoalsPromises:
    | UseQueryOptions<OpenCareGoal, unknown, OpenCareGoal, QueryKey>[]
    | [] = useMemo(() => {
    let openCareGoalIds = goalsLogs?.logs?.map((goal) => goal.careTaskId)
    openCareGoalIds = Array.from(new Set(openCareGoalIds))

    if (openCareGoalIds.length > 0) {
      const goalLogsQuery = openCareGoalIds.map((goal) => {
        return {
          queryKey: [QUERY_KEYS.GOALSLOGS, goal],
          queryFn: () => fetchOpenCareGoals(goal.toString()),
        }
      })

      return goalLogsQuery
    }

    return []
  }, [goalsLogs, queries])
  const openCareGoalsQueries = useQueries(openCareGoalsPromises)

  const openCareGoals = useMemo(() => {
    return openCareGoalsQueries.reduce(
      (accumulator: { [goalId: string]: OpenCareGoal }, currentValue) => {
        const goal = currentValue.data ?? ({} as OpenCareGoal)
        const goalId = currentValue.data?.id ?? ''

        if (!accumulator[goalId]) {
          accumulator[goalId] = {} as OpenCareGoal
        }

        accumulator[goalId] = goal
        return accumulator
      },
      {}
    )
  }, [openCareGoalsQueries])

  return {
    goalsLogs,
    overViewGoalsLogs,
    openCareGoals,
  }
}

export default useGoalOverview
