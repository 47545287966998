import { datadogRum } from '@datadog/browser-rum'
import { Context, SourceBridge } from '@allara-health/source-health-bridge'
import { createContext, memo, ReactNode, useEffect, useState } from 'react'

export type AuthHeader = { 'x-source-plugin-token': string }

export interface SourcePluginContextInterface {
  pluginContext?: Context
  getAuthHeader: () => Promise<AuthHeader>
}

export const SourcePluginContext = createContext<SourcePluginContextInterface>(
  {} as SourcePluginContextInterface
)

interface SourcePluginProviderProps {
  children: ReactNode
}

export const SourcePluginProvider: React.FC<SourcePluginProviderProps> = memo(
  ({ children }) => {
    const [pluginContext, setPluginContext] = useState<Context | undefined>(
      undefined
    )

    const getAuthHeader = async () => {
      const auth = await SourceBridge.currentToken()
      return { 'x-source-plugin-token': auth.token }
    }

    // For Dev uncomment this and comment above function
    // const getAuthHeader = async () => {
    //   return { 'x-source-plugin-token': 'token' }
    // }

    const onContextUpdate = async (context: Context) => {
      setPluginContext(context)

      SourceBridge.ready()
      datadogRum.setUser({
        id: context.member,
      })
    }

    const init = async () => {
      await SourceBridge.onContextUpdate(onContextUpdate)
      await SourceBridge.init()

      datadogRum.startSessionReplayRecording()
    }

    useEffect(() => {
      init()
      // For Dev uncomment this and comment init()
      // setPluginContext({ member: 'mem_h0P4A4M4bood56ss438H' })
    }, [])

    return (
      <SourcePluginContext.Provider value={{ pluginContext, getAuthHeader }}>
        {children}
      </SourcePluginContext.Provider>
    )
  }
)
