import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import Loader from '../Loader'

interface LoadingDialogProps {
  open: boolean
  text?: string
  handleClose: () => void
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({
  open,
  text,
  handleClose,
}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Please Wait</DialogTitle>
      <DialogContent>
        <div className="py-3">
          <Loader size="sm" />
        </div>
        <DialogContentText>{text ?? 'Loading...'}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog
