import { useCallback } from 'react'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useAppointmentNotes from '../../hooks/useAppointmentNotes'
import useMember from '../../hooks/useMember'
import { Appointment, AppointmentNote } from '../../types/types'
import { formatName } from '../../utils/provider.utils'
import { formatMMDDYYYY } from '../../utils/time.utils'
import './styles.scss'
interface PostAppointmentNoteTableRowProps {
  appointment: Appointment
  viewNote: (note: AppointmentNote, appointment: Appointment) => void
  createNew: (appointment: Appointment) => void
}

const PostAppointmentNoteTableRow: React.FC<
  PostAppointmentNoteTableRowProps
> = ({ appointment, createNew, viewNote }) => {
  const { pluginContext } = useSourcePluginContext()
  const { member } = useMember(pluginContext?.member ?? undefined)
  const { notes } = useAppointmentNotes(pluginContext?.member ?? undefined)

  const note = notes.find((n) => n.appointment.id === appointment.id)

  const handleViewNote = useCallback(
    () => viewNote(note!, appointment), // eslint-disable-line
    [note, appointment]
  )

  const handleCreateNote = useCallback(
    () => createNew(appointment),
    [appointment]
  )

  return (
    <div className="d-flex justify-content-evenly align-items-center ">
      <p className="appointment-notes-table-cell text">
        {formatName(appointment.provider)}
      </p>
      <p className="appointment-notes-table-cell-small text">
        {appointment.type.category}
      </p>
      <p className="appointment-notes-table-cell-small text">
        {formatMMDDYYYY(new Date(appointment.time))}
      </p>
      <div className="d-flex justify-content-center appointment-notes-table-cell">
        {note && (
          <p className="link spaced-capital-letters" onClick={handleViewNote}>
            View Note {note.status !== 'signed' ? `(${note.status})` : ''}
          </p>
        )}
        {!note && member !== undefined && (
          <p
            className="spaced-capital-letters btn-text text"
            onClick={handleCreateNote}
          >
            Create New
          </p>
        )}
      </div>
    </div>
  )
}

export default PostAppointmentNoteTableRow
