import { Form, FormSelectProps } from 'react-bootstrap'

interface SelectProps extends FormSelectProps {
  label?: string
  controlId?: string
  containerClassName?: string
  options: { label: string; value: string }[]
}

const Select: React.FC<SelectProps> = ({
  label,
  controlId = 'form-control',
  containerClassName = '',
  options,
  value,
  onChange,
  ...inputProps
}) => {
  return (
    <Form.Group
      controlId={controlId}
      className={`select ${containerClassName}`}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select value={value} onChange={onChange} {...inputProps}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default Select
