"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRequestId = void 0;
// From https://stackoverflow.com/a/10727155
function randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i)
        result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}
function generateRequestId() {
    return randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}
exports.generateRequestId = generateRequestId;
