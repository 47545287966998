import { ChevronRight } from '@material-ui/icons'
import { memo, useCallback, useMemo } from 'react'
import PrimaryButton from '../../components/Button/PrimaryButton'
import Select from '../../components/Select'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useMember from '../../hooks/useMember'
import './styles.scss'

export const options = [
  { label: 'Select One', value: '' },
  { label: 'Full Care Program', value: 'full-program' },
  {
    label: 'Nutrition Only Program',
    value: 'nutrition-only',
  },
  { label: 'No Followup', value: 'none' },
]

const RecommendedProgram: React.FC = () => {
  const { pluginContext } = useSourcePluginContext()
  const { member } = useMember(pluginContext?.member ?? undefined)
  const { nextScreen, updateRecommendedProgram, recommendedProgram } =
    usePostAppointmentContext()

  const handleNext = useCallback(() => nextScreen(), [])

  const filteredOptions = useMemo(() => {
    if (member?.stripeCustomer?.product?.type === 'insurance-diagnostic') {
      return options.filter((o) => o.value !== 'nutrition-only')
    } else {
      return options
    }
  }, [member])

  return (
    <div className="recommended-program d-flex align-items-center flex-column">
      <div className="description mt-5 fs-6">
        Do you recommend this patient continues with Allara Care?
      </div>
      <p className="description mt-2">
        Please Note: Your follow up plan for a Diagnostic patient will only
        become active if the patient continues with a full membership.
      </p>
      <div className="d-flex justify-content-center align-items-center select mt-3">
        <Select
          value={recommendedProgram}
          options={filteredOptions}
          onChange={updateRecommendedProgram}
        />
      </div>
      <div className="position-fixed bottom-4 d-flex justify-content-center align-items-center mt-2">
        <PrimaryButton
          onClick={handleNext}
          disabled={!recommendedProgram}
          className="button-primary"
        >
          Confirm
          <ChevronRight className="space-8" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default memo(RecommendedProgram)
