import React, { useCallback, useMemo, useState } from 'react'
import {
  DatePicker,
  DatePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Goal } from '../../types/types'
import './styles.scss'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import GoalItem from './GoalItem'
import CalenderView from './CalendarView'
import useGoalOverview from '../../hooks/useGoalOverview'
import Switch, { SwitchProps } from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'

type GoalOverviewProps = {
  goals: Goal[]
}

const GoalOverview = ({ goals }: GoalOverviewProps) => {
  const { pluginContext } = useSourcePluginContext()

  // Set default start date to the start of the first week of the current month
  const startOfMonth = dayjs().startOf('month').startOf('week')
  // Set default end date to the end of the last week of the current month
  const endOfWeekOfCurrentDate = dayjs().endOf('week')
  const [startDate, setStartDate] = React.useState<Dayjs>(startOfMonth)
  const [endDate, setEndDate] = React.useState<Dayjs>(endOfWeekOfCurrentDate)
  const [calendarDateToUse, setcalendarDateToUse] = useState<Dayjs>(dayjs)
  const [checked, setChecked] = useState<boolean>(true)
  const handleChecked = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  // Ensure the start date is at the start of the week
  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      const start = date.startOf('week')
      setStartDate(start)

      if (endDate && start.isAfter(endDate)) {
        setEndDate(start.endOf('week'))
      }
    } else {
      setStartDate(date ?? startOfMonth)
    }
  }

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      const end = date.endOf('week')
      setEndDate(end)

      if (startDate && end.isBefore(startDate)) {
        setStartDate(end.startOf('week'))
      }
    } else {
      setEndDate(date ?? endOfWeekOfCurrentDate)
    }
  }

  const handleShouldDisableStartDate = useCallback(
    (date) =>
      date?.day() !== dayjs().startOf('week').day() ||
      (endDate ? date.isAfter(endDate) : false),
    [endDate]
  )

  const handleShouldDisableEndDate = useCallback(
    (date) =>
      date?.day() !== dayjs().endOf('week').day() ||
      (startDate ? date.isBefore(startDate) : false) ||
      date.isAfter(endOfWeekOfCurrentDate),
    [startDate]
  )

  const goalsFiltered = useMemo(() => {
    return goals.filter((goal) => {
      return goal.status === 'active'
    })
  }, [goals, startDate, endDate])

  const { goalsLogs, overViewGoalsLogs, openCareGoals } = useGoalOverview(
    pluginContext?.member ?? '',
    calendarDateToUse,
    startDate,
    endDate,
    goalsFiltered,
    checked
  )

  const dateSlotProps: DatePickerSlotsComponentsProps<Dayjs> = {
    textField: {
      variant: 'standard',
    },
  }

  return (
    <div className="d-flex justify-content-between goal-overview mb-5">
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row gap-3 align-items-center">
              <div className="d-flex gap-3 calender-row">
                <DatePicker
                  disabled={checked}
                  label="Starting Date"
                  slotProps={dateSlotProps}
                  value={startDate}
                  onChange={handleStartDateChange}
                  shouldDisableDate={handleShouldDisableStartDate}
                />
                <DatePicker
                  disabled={checked}
                  label="Ending Date"
                  slotProps={dateSlotProps}
                  value={endDate}
                  onChange={handleEndDateChange}
                  shouldDisableDate={handleShouldDisableEndDate}
                />
              </div>
            </div>
            <div onClick={handleChecked} className="d-flex align-items-center">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    value={checked}
                    onClick={handleChecked}
                    defaultChecked={checked}
                  />
                }
                label={!checked ? 'Confirm date range' : 'Edit date range'}
              />
            </div>
          </div>
        </LocalizationProvider>
        {goalsFiltered.length > 0 && (
          <div className="mt-5">
            {goalsFiltered.map((goal, index) => {
              const goalsLogsFiltered =
                overViewGoalsLogs[goal.id]?.filter((log) => log.completed) ?? []
              return (
                <GoalItem
                  key={index}
                  date={new Date()}
                  goal={goal}
                  goalLogs={goalsLogsFiltered}
                  startDate={startDate}
                  endDate={endDate}
                />
              )
            })}
          </div>
        )}
      </div>
      <div>
        <CalenderView
          openCareGoals={openCareGoals}
          dateToUse={calendarDateToUse}
          setDateToUse={setcalendarDateToUse}
          goalLogs={goalsLogs}
        />
      </div>
    </div>
  )
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#141743',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#141743',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default GoalOverview
