import { memo, useCallback, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import { ReactComponent as Check } from '../../assets/images/check.svg'
import './styles.scss'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'
import { options as recommendedProgramOptions } from './RecommendedProgram'
import { getAppointmentType } from '../../utils/appointment.utils'
import Linkify from '../../components/Linkify'

const Review: React.FC = () => {
  const {
    selectedNote,
    labsResponse,
    imagingResponse,
    prescribedGLP1,
    referToSpecialist,
    timeline,
    note,
    noFollowUpReason,
    recommendedProgram,
    selectedTags,
    selectedAppointment,
    selectedFiles,
    isLoading,
    confirmAndSend,
  } = usePostAppointmentContext()

  const previouslySignedNote = useMemo(
    () => (selectedNote?.status === 'signed' ? selectedNote : null),
    [selectedNote]
  )

  const testOrderedText = useMemo(() => {
    const noFollowupReason =
      previouslySignedNote?.noFollowupReason ?? noFollowUpReason

    if (noFollowUpReason) {
      return `No followup - ${noFollowupReason}`
    }

    const moreLabsOrdered = previouslySignedNote?.labsOrdered ?? labsResponse
    const moreImagingOrdered =
      previouslySignedNote?.imagingOrdered ?? imagingResponse

    if (moreLabsOrdered === undefined && moreImagingOrdered === undefined) {
      return undefined
    } else if (moreLabsOrdered && moreImagingOrdered) {
      return 'Labs & Imaging'
    } else if (moreLabsOrdered) {
      return 'Labs'
    } else if (moreImagingOrdered) {
      return 'Imaging'
    } else {
      return undefined
    }
  }, [previouslySignedNote, labsResponse, imagingResponse, noFollowUpReason])

  const prescribedGLP1Text = useMemo(() => {
    if (previouslySignedNote?.prescribedGLP1 !== undefined) {
      return previouslySignedNote?.prescribedGLP1 ? 'True' : 'False'
    }

    return prescribedGLP1 ? 'True' : 'False'
  }, [prescribedGLP1, previouslySignedNote])

  const referToSpecialistText = useMemo(() => {
    if (previouslySignedNote?.referToSpecialist) {
      return previouslySignedNote.referToSpecialist
    }

    return referToSpecialist
  }, [referToSpecialist, previouslySignedNote])

  const followupInText = useMemo(() => {
    if (previouslySignedNote?.followup) {
      return previouslySignedNote.followup
    } else if (timeline) {
      return timeline
    } else {
      return undefined
    }
  }, [timeline, previouslySignedNote])

  const recommendedProgramText = useMemo(() => {
    const program =
      previouslySignedNote?.recommendedProgram ?? recommendedProgram

    if (program) {
      return (
        recommendedProgramOptions.find((o) => o.value === program)?.label ??
        undefined
      )
    } else {
      return undefined
    }
  }, [recommendedProgram, previouslySignedNote])

  const appointmentType = selectedAppointment
    ? getAppointmentType(selectedAppointment)
    : undefined

  const selectedFilesRemapped = useMemo(
    () => selectedFiles.map((file) => [...Array.from(file)]).flat(),
    [selectedFiles]
  )

  const handleSign = useCallback(
    () => confirmAndSend(selectedNote?.id),
    [selectedNote]
  )

  const handleSaveDraft = useCallback(
    () => confirmAndSend(selectedNote?.id, true),
    [selectedNote]
  )

  return (
    <div className="review align-self-center d-flex align-items-center justify-content-center flex-column w-100">
      <div className="container mt-2">
        {testOrderedText && (
          <div className="mt-3">
            <span className="label">Tests before next visit: </span>
            <span>{testOrderedText}</span>
          </div>
        )}
        {prescribedGLP1Text && (
          <div className="mt-3">
            <span className="label">Prescribed GLP-1: </span>
            <span>{prescribedGLP1Text}</span>
          </div>
        )}
        {referToSpecialistText && (
          <div className="mt-3">
            <span className="label">Referal to specialist: </span>
            <span>{referToSpecialistText}</span>
          </div>
        )}
        {followupInText && (
          <div className="mt-3">
            <span className="label">Follow up in: </span>
            <span>{followupInText}</span>
          </div>
        )}
        {recommendedProgramText && (
          <div className="mt-3">
            <span className="label">Recommended program: </span>
            <span>{recommendedProgramText}</span>
          </div>
        )}
        {!previouslySignedNote &&
          appointmentType !== 'nutrition' &&
          selectedTags && (
            <div className="mt-3">
              <span className="label">Source tags: </span>
              <span>{selectedTags.map((t) => t.name).join(', ')}</span>
            </div>
          )}
        <p className="label mt-3">Note for patient:</p>
        <Linkify>
          <pre className="ff-inter-medium">
            <p>{previouslySignedNote ? previouslySignedNote.text : note}</p>
          </pre>
        </Linkify>
        {previouslySignedNote ? (
          <div className="d-flex justify-content-end align-items-end mt-3 flex-column">
            {previouslySignedNote.attachments &&
              previouslySignedNote.attachments.map((file, index) => (
                <a
                  href={file.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="attachment-check d-flex gap-2 align-items-center"
                >
                  <Check />
                  {file.fileName}
                </a>
              ))}
          </div>
        ) : (
          <div className="d-flex justify-content-end align-items-end mt-3 flex-column">
            {selectedFilesRemapped &&
              selectedFilesRemapped.map((file, index) => (
                <div
                  key={index}
                  className="attachment-check d-flex gap-2 align-items-center"
                >
                  <Check />
                  {file.name}
                </div>
              ))}
          </div>
        )}
      </div>
      {!previouslySignedNote && (
        <div className="mt-5">
          <PrimaryButton
            className="button-primary"
            onClick={handleSign}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Confirm & Send
          </PrimaryButton>
          <div
            onClick={handleSaveDraft}
            className="save-as-draft text-center mt-4"
          >
            Save as Draft
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Review)
