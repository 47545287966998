import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { AlertProvider } from '../../context/AlertContext/AlertContextProvider'
import { SourcePluginProvider } from '../../context/SourcePluginContext/SourcePluginContextProvider'
import { LoadingDialogProvider } from '../../context/LoadingDialogContext/LoadingDialogContextProvider'
import Routes from './Routes'
import ErrorBoundary from './ErrorBoundary'
import React from 'react'
import { Container } from '@material-ui/core'
import '../../theme/main.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60, // 60s
    },
  },
})

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AlertProvider>
          <LoadingDialogProvider>
            <SourcePluginProvider>
              <QueryClientProvider client={queryClient}>
                <Container className="Container">
                  <Routes />
                </Container>
              </QueryClientProvider>
            </SourcePluginProvider>
          </LoadingDialogProvider>
        </AlertProvider>
      </Router>
    </ErrorBoundary>
  )
}

export default App
