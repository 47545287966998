import './styles.scss'

export interface LoaderProps {
  variant?: string
  size?: 'sm' | 'md'
}

const Loader: React.FC<LoaderProps> = ({
  variant = 'primary',
  size = 'md',
}) => {
  return (
    <div className={`loader loader-${size}`}>
      <div className={`dot dot-${size} bg-${variant}`} />
      <div className={`dot dot-${size} bg-${variant}`} />
      <div className={`dot dot-${size} bg-${variant}`} />
      <div className={`dot dot-${size} bg-${variant}`} />
      <div className={`dot dot-${size} bg-${variant}`} />
    </div>
  )
}

export default Loader
