import { PrimaryButton } from '../../components/Button'
import { v4 as uuidv4 } from 'uuid'
import { useSourcePluginContext } from '../../context/SourcePluginContext'
import useGoals from '../../hooks/useGoals'
import GoalRow from './GoalRow'
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg'
import { memo, useCallback, useState } from 'react'
import { Goal } from '../../types/types'
import useMember from '../../hooks/useMember'
import GoalOverview from './GoalOverview'

const GoalsV2: React.FC = () => {
  const { pluginContext } = useSourcePluginContext()
  const { member } = useMember(pluginContext?.member ?? undefined)
  const { activeGoals, pausedGoals, completedGoals, goals } = useGoals(
    pluginContext?.member ?? undefined
  )
  const [newGoals, setNewGoals] = useState<Goal[]>([])
  const createNewGoal = useCallback(() => {
    if (member) {
      const newGoal: Goal = {
        id: uuidv4(),
        text: '',
        category: {
          id: 0,
          name: '',
        },
        status: 'active',
        user: member,
      }

      setNewGoals((currentNewGoals) => [...currentNewGoals, newGoal])
    }
  }, [member])

  const cancelNewGoal = useCallback((id: string) => {
    setNewGoals((currentNewGoals) => currentNewGoals.filter((g) => g.id !== id))
  }, [])

  return (
    <div className="mt-6 px-6">
      <div className="d-flex align-items-center justify-content-between">
        <div />
        <p className="h1 mb-2 mt-2 fs-12">Goals v2</p>
        <PrimaryButton
          className="d-flex align-items-center"
          onClick={createNewGoal}
        >
          <PlusIcon style={{ width: '25px', height: '25px' }} />
          Create New Goal
        </PrimaryButton>
      </div>
      <br />
      <p className="h5">Active Goals</p>
      <div className="goals-table-container">
        {activeGoals.map((goal, i) => (
          <GoalRow key={goal.id} goal={goal} index={i} />
        ))}
        {newGoals.length !== 0 &&
          newGoals.map((goal, i) => (
            <GoalRow
              key={goal.id}
              goal={goal}
              index={activeGoals.length + i}
              cancelCreate={() => cancelNewGoal(goal.id)}
            />
          ))}
        {activeGoals.length + newGoals.length === 0 && (
          <p className="mb-4">No active goals</p>
        )}
      </div>
      <h5 className="mt-5">Goal Progress</h5>
      <GoalOverview goals={goals} />
      <p className="h5">Tabled (Paused) Goals</p>
      <div className="goals-table-container">
        {pausedGoals.map((goal, i) => (
          <GoalRow key={goal.id} goal={goal} index={i} />
        ))}
        {pausedGoals.length === 0 && <p className="mb-4">No paused goals</p>}
      </div>
      <p className="h5">Completed Goals</p>
      <div className="goals-table-containe pb-4">
        {completedGoals.map((goal, i) => (
          <GoalRow key={goal.id} goal={goal} index={i} />
        ))}
        {completedGoals.length === 0 && (
          <p className="mb-4">No completed goals</p>
        )}
      </div>
    </div>
  )
}

export default memo(GoalsV2)
