import { Route, Routes as RouteSwitch } from 'react-router-dom'
import Goals from '../Goals'
import OnboardingData from '../OnboardingData'
import PostAppointmentNote from '../PostAppointment'
import GoalsV2 from '../Goals-v2'

export const RoutePaths = {
  GOALS: '/goals',
  GOALSV2: '/goals-v2',
  ONBOARDING_DATA: '/onboarding',
  POST_APPOINTMENT_NOTE: '/post-appointment-note',
}

const Routes: React.FC = () => {
  return (
    <RouteSwitch>
      <Route path={RoutePaths.GOALS} element={<Goals />} />
      <Route path={RoutePaths.GOALSV2} element={<GoalsV2 />} />
      <Route path={RoutePaths.ONBOARDING_DATA} element={<OnboardingData />} />
      <Route
        path={RoutePaths.POST_APPOINTMENT_NOTE}
        element={<PostAppointmentNote />}
      />
    </RouteSwitch>
  )
}

export default Routes
