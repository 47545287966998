import { useQuery, useQueryClient } from 'react-query'
import { useEffect, useMemo } from 'react'
import { Appointments as AppointmentsRoute } from '../api/routes'
import { QUERY_KEYS } from '../api/querykeys'
import { Appointment } from '../types/types'
import { useSourcePluginContext } from '../context/SourcePluginContext'
import { useAlertContext } from '../context/AlertContext'

interface UseAppointmentsInterface {
  isLoading: boolean
  error: unknown
  appointments: Appointment[]
  completedAppointments: Appointment[]
}

const useAppointments = (member?: string): UseAppointmentsInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { getAuthHeader } = useSourcePluginContext()

  const fetchAppointments = async (): Promise<Appointment[]> => {
    if (!member) {
      return []
    }

    const authHeader = await getAuthHeader()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentsRoute(member)}`,
      { headers: authHeader }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else if (res.status === 403) {
          setAlertText('Unauthorized - you may need to reload the page')
          return []
        } else {
          setAlertText(`Error loading appointments (${res.statusText})`)
          return []
        }
      })
      .catch(() => {
        setAlertText(`Error loading appointments`)
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    [QUERY_KEYS.APPOINTMENTS, member ?? 'default'],
    fetchAppointments
  )

  const appointments = useMemo(() => data ?? [], [data])

  const completedAppointments = useMemo(
    () =>
      appointments.filter(
        (appt) => new Date(appt.time).getTime() <= Date.now()
      ),
    [appointments]
  )

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.APPOINTMENT_NOTES)
  }, [member])

  return {
    isLoading,
    error,
    appointments,
    completedAppointments,
  }
}

export default useAppointments
