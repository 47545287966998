import { createContext, memo, ReactNode, useCallback, useState } from 'react'
import LoadingDialog from '../../components/LoadingDialog'

export interface LoadingDialogContextInterface {
  open: (text?: string) => void
  close: () => void
}

export const LoadingDialogContext =
  createContext<LoadingDialogContextInterface>(
    {} as LoadingDialogContextInterface
  )

interface LoadingDialogProviderProps {
  children: ReactNode
}

export const LoadingDialogProvider: React.FC<LoadingDialogProviderProps> = memo(
  ({ children }) => {
    const [show, setShow] = useState<boolean>(false)
    const [text, setText] = useState<string | undefined>(undefined)

    const open = useCallback((text?: string) => {
      setText(text)
      setShow(true)
    }, [])

    const close = useCallback(() => {
      setText(undefined)
      setShow(false)
    }, [])

    return (
      <LoadingDialogContext.Provider value={{ open, close }}>
        {children}
        <LoadingDialog open={show} text={text} handleClose={close} />
      </LoadingDialogContext.Provider>
    )
  }
)
