import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import './styles.scss'
import { usePostAppointmentContext } from '../../context/PostAppointmentContext/PostAppointmentProvider'
import Select from '../../components/Select'
import { Form } from 'react-bootstrap'

const Tests: React.FC = () => {
  const {
    updateTestsResponse,
    updateNoReasonFollowUp,
    selectedAppointment,
    noFollowUpReason,
    showNoFollowUp,
    noFollowUpOtherReason,
    updateShowFollowUp,
    nextScreen,
    updateNoFollowUpOtherReason,
  } = usePostAppointmentContext()
  const labsOrderedRef = useRef<HTMLInputElement>(null)
  const imagingOrderedRef = useRef<HTMLInputElement>(null)
  const prescribedGLP1Ref = useRef<HTMLInputElement>(null)
  const referToSpecialistRef = useRef<HTMLInputElement>(null)
  const [referToSpecialistChecked, setReferToSpecialistChecked] =
    useState<boolean>(false)

  const handleReferToSpecialistChecked = useCallback(
    () => setReferToSpecialistChecked((prev) => !prev),
    []
  )

  const handleConfirm = useCallback(
    () => nextScreen(undefined, { noFollowUp: showNoFollowUp }),
    [noFollowUpReason]
  )

  const handleNoneOrdered = useCallback(() => {
    if (selectedAppointment?.type.category === 'Initial HMT') {
      updateShowFollowUp()
    } else {
      updateTestsResponse(false, false, false)
    }
  }, [selectedAppointment])

  const handleNext = useCallback(() => {
    updateTestsResponse(
      labsOrderedRef.current?.checked ?? false,
      imagingOrderedRef.current?.checked ?? false,
      prescribedGLP1Ref.current?.checked ?? false,
      referToSpecialistRef.current?.value
    )
  }, [
    labsOrderedRef.current,
    imagingOrderedRef.current,
    prescribedGLP1Ref.current,
    referToSpecialistRef.current,
  ])

  const showGLP = useMemo(
    () => (selectedAppointment?.type.category === 'Diagnostic' ? false : true),
    [selectedAppointment]
  )

  return (
    <div className="tests d-flex justify-content-center align-items-center align-self-center flex-column">
      {!showNoFollowUp && (
        <>
          <div className="description mt-5">
            Did you order labs or imaging for the patient to complete before
            their next visit?
            <br />
            Note: these should be ordered in Elation
          </div>
          <div className="mt-5 d-flex flex-column gap-3">
            <Form>
              <Form.Check
                type="checkbox"
                label="Labs Ordered"
                id="labsOrdered"
                ref={labsOrderedRef}
              />
              <Form.Check
                type="checkbox"
                label="Imaging Ordered"
                id="imagingOrdered"
                ref={imagingOrderedRef}
              />
              {showGLP && (
                <Form.Check
                  type="checkbox"
                  label="Prescribed GLP-1"
                  id="prescribedGLP1"
                  ref={prescribedGLP1Ref}
                />
              )}
              <Form.Check
                type="checkbox"
                checked={referToSpecialistChecked}
                onChange={handleReferToSpecialistChecked}
                label="Refer to Specialist"
                id="referToSpecialistCheck"
              />
              {referToSpecialistChecked && (
                <>
                  <Form.Control
                    type="text"
                    placeholder="Specialist Type"
                    id="referToSpecialist"
                    ref={referToSpecialistRef}
                  />
                </>
              )}
            </Form>
            <SecondaryButton onClick={handleNext} className="px-4 py-2">
              Next
            </SecondaryButton>
          </div>
        </>
      )}

      {showNoFollowUp && (
        <div className="no-follow-up-dialog d-flex justify-content-center align-items-start flex-column">
          <p className="text">
            Are you sure you don&apos;t want to provide a follow up plan for
            this patient?
          </p>
          <p className="text text-sub">
            NOTE: If there is no follow up plan they will see nothing on their
            dashboard and won&apos;t be prompted to book with you again.
            <br />
            This should only be done if the patient has a previously set plan or
            is not continuing with Allara care.
          </p>
          <div className="mt-5 px-3">
            <Select
              label="Select Reason"
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                updateNoReasonFollowUp(event.target.value)
              }}
              value={noFollowUpReason}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Patient is cancelling',
                  value: 'Patient is cancelling',
                },
                {
                  label: 'Ad hoc Appointment',
                  value: 'Ad hoc Appointment',
                },
                {
                  label: 'Patient is becoming Nutrition only',
                  value: 'Patient is becoming Nutrition only',
                },
                {
                  label: 'Other (please let concierge know)',
                  value: 'Other (please let concierge know)',
                },
              ]}
            />
            {noFollowUpReason === 'Other (please let concierge know)' && (
              <div className="mt-4">
                <Form.Control
                  placeholder="Provide text"
                  value={noFollowUpOtherReason}
                  onChange={updateNoFollowUpOtherReason}
                  type="text"
                />
              </div>
            )}
          </div>
          <div className="mt-7 d-flex justify-content-between w-100 px-5 flex-wrap align-items-center gap-3">
            <PrimaryButton
              className="button-primary"
              onClick={updateShowFollowUp}
            >
              Go back
            </PrimaryButton>
            <SecondaryButton
              disabled={!noFollowUpReason}
              className="button-secondary"
              onClick={handleConfirm}
            >
              Confirm
            </SecondaryButton>
          </div>
        </div>
      )}
      <div className={`d-flex flex-column align-items-center mt-12`}>
        <div onClick={handleNoneOrdered} className="no-follow-up">
          None ordered
        </div>
        <div onClick={updateShowFollowUp} className="no-follow-up mt-2 mb-3">
          No follow up
        </div>
      </div>
    </div>
  )
}

export default memo(Tests)
